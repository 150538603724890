<h1 mat-dialog-title>{{data.name}}</h1>
<div mat-dialog-content>
   <div *ngIf="data.error.error.errorDetails" class=" {{data.error.status === 422 ? '' : 'alert alert-danger' }}">
     <span *ngIf="data.error.status !== 422">
      <p *ngFor="let error of data.error.error.errorDetails">
        <b>{{error.field}}:</b> {{error.defaultMessage}}
      </p>
     </span>
     <span *ngIf="data.error.status === 422">
      <p>
        {{data.error.error.errorDetails.defaultMessage}}
      </p>
     </span>
  </div>
  <div *ngIf="!data.error.error.errorDetails" class="alert alert-danger">
    <span>
      <p>
        <b>{{data.error.error.message ? data.error.error.message : data.error.error}}</b>
      </p>
    </span>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>
