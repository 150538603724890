import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { RewardService } from 'src/app/services/reward.service';

@Component({
  selector: 'app-create-reward-type',
  templateUrl: './create-reward-type.component.html',
  styleUrls: ['./create-reward-type.component.css']
})
export class CreateRewardTypeComponent implements OnInit {

  regra: any = {};

  regraForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl = '/tipos-premios';
  error = '';
  page: any = {base: null, name: null};
  tipos;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastrService,
    private rewardService: RewardService,)  {
      this.rewardService.getAllTypesEnum().pipe(first())
      .subscribe(
          data => {
            console.log('tipos', data);
            this.tipos = data;
          },
          error => {
            console.log('error', error);
        });
    }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.rewardService.getType(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.regra = data;
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.regraForm = this.fb.group({
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        typesReward: new FormControl('', Validators.required),
    });
    if (this.page.name === 'view')
    this.regraForm.disable();

    }

      onSubmit(page): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.regraForm.invalid) {
          console.log('invaid', this.regraForm);
          return;
        }
        console.log('valid', this.regra);
        this.loading = true;

        if (page === 'edit'){
          this.rewardService.updateType(this.regra, this.regra.id)
            .subscribe(
                data => {
                  this.toast.success('Editaste um Tipo de Prémio com sucesso', 'Edição', { positionClass: 'toast-top-center' });
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });

        }
        else if (page === 'create'){
          this.rewardService.createType(this.regra)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Criaste um Tipo de Prémio com sucesso', 'Criação', { positionClass: 'toast-top-center' });
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  this.loading = false;
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.regraForm.enable();
    }

}

