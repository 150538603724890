import { Component, ElementRef, Input, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { Validators, Editor, Toolbar, toHTML } from "ngx-editor";


@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmailComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef; files  = [];
  editor: Editor;
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"]
  ];

  form = new FormGroup({
    editorContent: new FormControl(null, Validators.required()),
    assunto: new FormControl(null, Validators.required())
  });
  submited = false;

  @Input() title: string;
  @Input() recepientes: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void  {
    this.editor = new Editor();
  }

  get f(): any { return this.form.controls; }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  public decline(): void {
    this.activeModal.close(false);
  }

  public accept(): void  {
    this.submited = true;
    console.log('form',this.form)
    if(this.form.invalid)
    return
    const html = toHTML(this.form.get("editorContent").value);
    const subject = this.form.get("assunto").value;
    console.log('editor content', html);
    this.activeModal.close({bodyText: html, subjects: subject, files: this.files});
  }

  public dismiss(): void  {
    this.activeModal.dismiss();
  }

  public removeAll(): void{
    this.files= [];
  }

  public onClick(): void {
    this.fileUpload.nativeElement.value = '';
    const fileUpload = this.fileUpload .nativeElement; fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++)
      {
       const file = fileUpload.files[index];
       const reader = new FileReader();
       reader.readAsDataURL(file);
       /*reader.onload = (_event) => {
          file.imgURL = reader.result;
        }*/
       console.log('file', file);
        this.files.push(file);
      }
      // this.uploadFiles(this.nivel.id);
      };
      fileUpload.click();
  }

}
