
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthService } from 'src/app/services/keycloak.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private authenticationService: KeycloakAuthService
  ) {
    // super(router, keycloak);
  }



  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const auth = JSON.parse(localStorage.getItem('auth'));
    //console.log('auth', auth);
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
        // logged in so return true
        return true;
    }
    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/']);
    this.authenticationService.init().then((response) => {
      console.log('====>>  guard', this.authenticationService.auth);
      this.router.navigate(['/home']);
    });
    //return false;
}
}
