<div class="container-fluid">
  <div class="col-12">
    <h2 class="text-siscomeTot filtroHeader">{{title}} <span class="badge badge-secondary">{{resultsLength}}</span></h2>
    <!-- <mat-form-field appearance="legacy" color="primary" class="col-12 filtro">
      <input matInput (keyup)="applyFilter(pesquisar)" [(ngModel)]="pesquisar" placeholder="" #input><mat-icon matSuffix>filter_list</mat-icon>
    </mat-form-field> -->
  </div>
  <div class="col-12" *ngIf="novo">
    <button mat-raised-button color="primary"><a class="text-white" routerLink={{novo.link}}>{{novo.nome}}</a><mat-icon>add_circle</mat-icon></button>
  </div>
  <div class="row" style="padding: 10px !important;">
    <div class="col-12">
      <button  class="customButtons" *ngFor="let button of buttons"  type="button" (click)="button.fn(dataSource, selection.selected)" mat-raised-button aria-label="Reset" color="primary">{{button.title}}<mat-icon>{{button.icon}}</mat-icon></button>
      <button  class="float-right" type="button" (click)="reset()" mat-raised-button aria-label="Reset" color="basic">Limpar filtros<mat-icon>refresh</mat-icon></button>
    </div>
  </div>
  <div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner color="primary" *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <table *ngIf="!options.expand" mat-table [dataSource]="dataSource" id="table-listing"
      matSort (matSortChange)="sortData()" arrowPosition='end'
      cdkDropList
          cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="drop($event)">

          <ng-container matColumnDef="select" *ngIf="options.select">
            <th mat-header-cell *matHeaderCellDef style="width: 3% !important;">
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" style="padding-left: 6px !important;">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

         <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columnsToDisplay">
           <th mat-header-cell   *matHeaderCellDef><div mat-sort-header><b>{{column.header}}</b></div>
            <div class="dropdown pull-right filtro">
              <button class='btn-sm btn btn-default dropdown-toggle' (click)="select(column.field)" data-toggle="dropdown" aria-expanded="true">
                <i class='fa fa-sort-desc pull-right'></i>
              </button>
              <ul id="dropdownMenu{{column.field}}" class="dropdown-menu">
                <div *ngIf="column.type == 'number'">
                    <li>
                      <div class="col-sm-9" style="margin-bottom:5px">
                        <input id="number-field-val-{{column.field}}"  min="0" type="number" class="form-control" [ngModel]="numMore" placeholder="Número">
                    </div>
                    <div class="col-sm-12">
                        <button (click)="searchColumn(column.field, numMore, 'number')"
                                    class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                            Pesquisar
                        </button>
                    </div>
                    </li>
                </div> <div class="clearfix"></div>
                <div *ngIf="column.type == 'date'">
                    <li>
                        <div class="col-sm-12" style="margin-bottom:5px">
                            <input id="date-field-val-{{column.field}}"  class="datepicker form-control datepicker-min-field-{{column.field}}" type="date" placeholder="Data" [ngModel]="dateMin">
                        </div>
                        <div class="col-sm-12">
                            <button (click)="searchColumn(column.field, dateMin, 'date')"
                                        class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                                Pesquisar
                            </button>
                        </div>
                    </li>
                </div> <div class="clearfix"></div>
                <div *ngIf="column.type == 'text'">
                    <li>
                        <div class="col-sm-12">
                          <input id="text-field-val-{{column.field}}" class="form-control" placeholder="Pesquisar"
                          [ngModel]="txtField">
                        </div>
                        <div class="col-sm-12">
                            <button (click)="searchColumn(column.field, txtField)" style="margin-top:5px"
                                        class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                                Pesquisar
                            </button>
                        </div>
                    </li>
                </div> <div class="clearfix"></div>
                <div *ngIf="column.type == 'select'">
                    <div class="col-sm-12">
                        <select class="form-control" [ngModel]="selectField"
                                id="select-field-val-{{column.field}}" (change)="searchColumnSel(column.field)">
                            <option value="{{dados.val || dados}}" *ngFor="let dados of column.selectFieldsDados">{{dados.name  || dados}}</option>
                        </select>
                    </div>
                </div> <div class="clearfix"></div>
              </ul>
            </div>
          <div class="col-filter-result" *ngIf="filterExist">
            {{colFilterValue(column.field)}}
          </div>
          </th>
          <tr mat-header-row ></tr>
           <td mat-cell *matCellDef="let element"> {{element[column.field]}} </td>
         </ng-container>
         <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columnsBadge">
          <th mat-header-cell  *matHeaderCellDef><div mat-sort-header><b>{{column.header}}</b></div>
            <div class="dropdown pull-right filtro">
              <button class='btn-sm btn btn-default dropdown-toggle' (click)="select(column.field)" data-toggle="dropdown" aria-expanded="true">
                <i class='fa fa-sort-desc pull-right'></i>
              </button>
              <ul id="dropdownMenu{{column.field}}" class="dropdown-menu">
                <div *ngIf="column.type == 'number'">
                    <li>
                      <div class="col-sm-9" style="margin-bottom:5px">
                        <input id="number-field-val-{{column.field}}"  min="0" type="number" class="form-control" [ngModel]="numMore" placeholder="Número">
                    </div>
                        <div class="col-sm-12">
                            <button (click)="searchColumn(column.field, numMore, 'number')"
                                        class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                                Pesquisar
                            </button>
                        </div>
                    </li>
                </div> <div class="clearfix"></div>
                <div *ngIf="column.type == 'date'">
                    <li>
                        <div class="col-sm-12" style="margin-bottom:5px">
                            <input class="datepicker form-control datepicker-min-field-{{column.field}}" type="date" placeholder="Apartir de" [ngModel]="dateMin">
                        </div>
                        <div class="col-sm-12" style="margin-bottom:5px">
                            <input class="datepicker form-control datepicker-max-field-{{column.field}}" type="date" placeholder="Até" [ngModel]="dateMax">
                        </div>
                        <div class="col-sm-12">
                            <button (click)="searchColumn(column.field, {min:dateMin,max:dateMax}, 'date')"
                                        class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                                Pesquisar
                            </button>
                        </div>
                    </li>
                </div> <div class="clearfix"></div>
                <div *ngIf="column.type == 'text'">
                    <li>
                        <div class="col-sm-12">
                          <input id="text-field-val-{{column.field}}" class="form-control" placeholder="Pesquisar"
                          [ngModel]="txtField">
                        </div>
                        <div class="col-sm-12">
                            <button (click)="searchColumn(column.field, txtField)" style="margin-top:5px"
                                        class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                                Pesquisar
                            </button>
                        </div>
                    </li>
                </div> <div class="clearfix"></div>
                <div *ngIf="column.type == 'select'">
                    <div class="col-sm-12">
                        <select class="form-control" [ngModel]="selectField"
                                id="select-field-val-{{column.field}}" (change)="searchColumnSel(column.field)">
                                <option value="{{dados.val || dados.id}}" *ngFor="let dados of column.selectFieldsDados">{{dados.name  || dados.signified}}</option>
                        </select>
                    </div>
                </div> <div class="clearfix"></div>
              </ul>
            </div>
            <div class="col-filter-result" *ngIf="filterExist">
              {{colFilterValue(column.field)}}
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element[column.field] === false" class="badge badge-danger">Não</span>
            <span *ngIf="element[column.field] === true" class="badge badge-success">Sim</span>
            <span *ngIf="element[column.field] === 'm'" class="badge badge-primary masculino">Masculino</span>
            <span *ngIf="element[column.field] === 'f'" class="badge badge-primary feminino">Feminino</span>
            <span *ngIf="element[column.field] === 'ACTIVE'" class="badge badge-success">ACTIVO</span>
            <span *ngIf="element[column.field] === 'INACTIVE'" class="badge badge-danger">INACTIVO</span>
          </td>
        </ng-container>
         <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columnsObject">
           <th mat-header-cell  *matHeaderCellDef><div mat-sort-header><b>{{column.header}}</b></div>
            <div  *ngIf="column.type" class="dropdown pull-right filtro">
              <button class='btn-sm btn btn-default dropdown-toggle' (click)="select(column.object)" data-toggle="dropdown" aria-expanded="true">
                <i class='fa fa-sort-desc pull-right'></i>
              </button>
              <ul id="dropdownMenu{{column.object}}" class="dropdown-menu">
                <div *ngIf="column.type == 'number'">
                  <li>
                    <div class="col-sm-9" style="margin-bottom:5px">
                      <input id="number-field-val-{{column.field}}"  min="0" type="number" class="form-control" [ngModel]="numMore" placeholder="Número">
                  </div>
                      <div class="col-sm-12">
                          <button (click)="searchColumn(column.field, numMore, 'number')"
                                      class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                              Pesquisar
                          </button>
                      </div>
                  </li>
              </div> <div class="clearfix"></div>
              <div *ngIf="column.type == 'date'">
                  <li>
                      <div class="col-sm-12" style="margin-bottom:5px">
                          <input class="datepicker form-control datepicker-min-field-{{column.field}}" type="date" placeholder="Apartir de" [ngModel]="dateMin">
                      </div>
                      <div class="col-sm-12" style="margin-bottom:5px">
                          <input class="datepicker form-control datepicker-max-field-{{column.field}}" type="date" placeholder="Até" [ngModel]="dateMax">
                      </div>
                      <div class="col-sm-12">
                          <button (click)="searchColumn(column.field, {min:dateMin,max:dateMax}, 'date')"
                                      class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                              Pesquisar
                          </button>
                      </div>
                  </li>
              </div> <div class="clearfix"></div>
              <div *ngIf="column.type == 'text'">
                  <li>
                      <div class="col-sm-12">
                        <input id="text-field-val-{{column.field}}" class="form-control" placeholder="Pesquisar"
                        [ngModel]="txtField">
                      </div>
                      <div class="col-sm-12">
                          <button (click)="searchColumn(column.field, txtField)" style="margin-top:5px"
                                      class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                              Pesquisar
                          </button>
                      </div>
                  </li>
              </div> <div class="clearfix"></div>
                <div *ngIf="column.type == 'select'">
                    <div class="col-sm-12">
                        <select class="form-control" [ngModel]="selectField"
                                id="select-field-val-{{column.object}}" (change)="searchColumnSel(column.object)">
                            <option value="{{dados.id || dados.storeId}}" *ngFor="let dados of column.selectFieldsDados">{{dados[column.subObject]}}</option>
                        </select>
                    </div>
                </div> <div class="clearfix"></div>
              </ul>
            </div>
          <div class="col-filter-result" *ngIf="filterExist">
            {{colFilterValue(column.object)}}
          </div>
          </th>
           <td mat-cell *matCellDef="let element">
             {{element[column.object] ? element[column.object][column.subObject] : ''}}
            </td>
         </ng-container>
        <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columnsObject2">
          <th mat-header-cell  *matHeaderCellDef><div mat-sort-header><b>{{column.header}}</b></div>
           <div  *ngIf="column.type" class="dropdown pull-right filtro">
             <button class='btn-sm btn btn-default dropdown-toggle' (click)="select(column.object + column.object2)" data-toggle="dropdown" aria-expanded="true">
               <i class='fa fa-sort-desc pull-right'></i>
             </button>
             <ul id="dropdownMenu{{column.object + column.object2}}" class="dropdown-menu">
               <div *ngIf="column.type == 'number'">
                 <li>
                   <div class="col-sm-9" style="margin-bottom:5px">
                     <input id="number-field-val-{{column.field}}"  min="0" type="number" class="form-control" [ngModel]="numMore" placeholder="Número">
                 </div>
                     <div class="col-sm-12">
                         <button (click)="searchColumn(column.field, numMore, 'number')"
                                     class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                             Pesquisar
                         </button>
                     </div>
                 </li>
             </div> <div class="clearfix"></div>
             <div *ngIf="column.type == 'date'">
                 <li>
                     <div class="col-sm-12" style="margin-bottom:5px">
                         <input class="datepicker form-control datepicker-min-field-{{column.field}}" type="date" placeholder="Apartir de" [ngModel]="dateMin">
                     </div>
                     <div class="col-sm-12" style="margin-bottom:5px">
                         <input class="datepicker form-control datepicker-max-field-{{column.field}}" type="date" placeholder="Até" [ngModel]="dateMax">
                     </div>
                     <div class="col-sm-12">
                         <button (click)="searchColumn(column.field, {min:dateMin,max:dateMax}, 'date')"
                                     class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                             Pesquisar
                         </button>
                     </div>
                 </li>
             </div> <div class="clearfix"></div>
             <div *ngIf="column.type == 'text'">
                 <li>
                     <div class="col-sm-12">
                       <input id="text-field-val-{{column.field}}" class="form-control" placeholder="Pesquisar"
                       [ngModel]="txtField">
                     </div>
                     <div class="col-sm-12">
                         <button (click)="searchColumn(column.field, txtField)" style="margin-top:5px"
                                     class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                             Pesquisar
                         </button>
                     </div>
                 </li>
             </div> <div class="clearfix"></div>
               <div *ngIf="column.type == 'select'">
                   <div class="col-sm-12">
                       <select class="form-control" [ngModel]="selectField"
                               id="select-field-val-{{column.field}}" (change)="searchColumnSel(column.field)">
                           <option value="{{dados.id}}" *ngFor="let dados of column.selectFieldsDados">{{dados[column.subObject]}}</option>
                       </select>
                   </div>
               </div> <div class="clearfix"></div>
             </ul>
           </div>
         <div class="col-filter-result" *ngIf="filterExist" >
           {{colFilterValue(column.object + '.'+ column.object2)}}
         </div>
         </th>
          <td mat-cell *matCellDef="let element">
            {{element[column.object][column.object2][column.subObject]}}
           </td>
        </ng-container>
        <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columnsObject3">
          <th mat-header-cell  *matHeaderCellDef><div mat-sort-header><b>{{column.header}}</b></div>
           <div  *ngIf="column.type" class="dropdown pull-right filtro">
             <button class='btn-sm btn btn-default dropdown-toggle' (click)="select(column.object + column.object2 + column.object3)" data-toggle="dropdown" aria-expanded="true">
               <i class='fa fa-sort-desc pull-right'></i>
             </button>
             <ul id="dropdownMenu{{column.object + column.object2 + column.object3}}" class="dropdown-menu">
               <div *ngIf="column.type == 'number'">
                 <li>
                   <div class="col-sm-9" style="margin-bottom:5px">
                     <input id="number-field-val-{{column.field}}"  min="0" type="number" class="form-control" [ngModel]="numMore" placeholder="Número">
                 </div>
                     <div class="col-sm-12">
                         <button (click)="searchColumn(column.field, numMore, 'number')"
                                     class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                             Pesquisar
                         </button>
                     </div>
                 </li>
             </div> <div class="clearfix"></div>
             <div *ngIf="column.type == 'date'">
                 <li>
                     <div class="col-sm-12" style="margin-bottom:5px">
                         <input class="datepicker form-control datepicker-min-field-{{column.field}}" type="date" placeholder="Apartir de" [ngModel]="dateMin">
                     </div>
                     <div class="col-sm-12" style="margin-bottom:5px">
                         <input class="datepicker form-control datepicker-max-field-{{column.field}}" type="date" placeholder="Até" [ngModel]="dateMax">
                     </div>
                     <div class="col-sm-12">
                         <button (click)="searchColumn(column.field, {min:dateMin,max:dateMax}, 'date')"
                                     class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                             Pesquisar
                         </button>
                     </div>
                 </li>
             </div> <div class="clearfix"></div>
             <div *ngIf="column.type == 'text'">
                 <li>
                     <div class="col-sm-12">
                       <input id="text-field-val-{{column.field}}" class="form-control" placeholder="Pesquisar"
                       [ngModel]="txtField">
                     </div>
                     <div class="col-sm-12">
                         <button (click)="searchColumn(column.field, txtField)" style="margin-top:5px"
                                     class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                             Pesquisar
                         </button>
                     </div>
                 </li>
             </div> <div class="clearfix"></div>
               <div *ngIf="column.type == 'select'">
                   <div class="col-sm-12">
                       <select class="form-control" [ngModel]="selectField"
                               id="select-field-val-{{column.field}}" (change)="searchColumnSel(column.field)">
                           <option value="{{dados.id}}" *ngFor="let dados of column.selectFieldsDados">{{dados[column.subObject]}}</option>
                       </select>
                   </div>
               </div> <div class="clearfix"></div>
             </ul>
           </div>
         <div class="col-filter-result" *ngIf="filterExist" >
           {{colFilterValue(column.object + '.' + column.object2 + '.' + column.object3)}}
         </div>
         </th>
          <td mat-cell *matCellDef="let element">
            {{element[column.object][column.object2][column.object3][column.subObject]}}
           </td>
        </ng-container>

         <ng-container matColumnDef="actions">
          <th mat-header-cell  *matHeaderCellDef><b>Ações</b>
          </th>
           <td mat-cell  *matCellDef="let element; let i = renderIndex;">
             <div class="row">
             <a  title="Ver" *ngIf="options.view" mat-icon-button aria-label="View" color="primary" (click)="view(element)">
              <mat-icon aria-hidden="false" aria-label="View" >remove_red_eye</mat-icon>
            </a>
            <a  title="Editar" *ngIf="options.edit" mat-icon-button aria-label="Edit" label="Edit" color="primary" (click)="edit(element)">
              <mat-icon aria-hidden="false">edit</mat-icon>
            </a>
            <a title="Activar" *ngIf="options.statusUpdate && element.status === 'INACTIVE'" mat-icon-button aria-label="Activar" color="primary" (click)="changeStatus(element)">
             <mat-icon>check_circle_outline</mat-icon>
           </a>
           <a title="Desactivar" *ngIf="options.statusUpdate && element.status === 'ACTIVE'" mat-icon-button aria-label="Desactivar" color="warn" (click)="changeStatus(element)">
             <mat-icon>remove_circle_outline</mat-icon>
           </a>
            <a title="Apagar" *ngIf="options.delete" mat-icon-button aria-label="Delete" color="warn" (click)="delete(dataSource, element, i)"> {{i}}
              <mat-icon aria-hidden="false" >delete</mat-icon>
            </a>
            <a *ngFor="let custom of options.custom" title="{{custom.title}}" mat-icon-button color="primary" (click)=custom.fn(element) >
              <mat-icon aria-hidden="false" >{{custom.icon}}</mat-icon>
            </a>
             </div>
           </td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns; let i = index"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
         <tr class="mat-row" *matNoDataRow>
           <td class="mat-cell" colspan="4">Nenhum dado encontrado  {{pesquisar ? 'para o filtro ' + pesquisar : ''}}</td>
         </tr>
  </table>
  <table *ngIf="options.expand" mat-table [dataSource]="dataSource" multiTemplateDataRows id="table-listing"
  matSort (matSortChange)="sortData()" arrowPosition='end'
  cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="drop($event)">

      <ng-container matColumnDef="select" *ngIf="options.select">
        <th mat-header-cell *matHeaderCellDef style="width: 3% !important;">
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" style="padding-left: 6px !important;">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

     <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columnsToDisplay">
       <th mat-header-cell   *matHeaderCellDef><div mat-sort-header><b>{{column.header}}</b></div>
        <div class="dropdown pull-right filtro">
          <button class='btn-sm btn btn-default dropdown-toggle' (click)="select(column.field)" data-toggle="dropdown" aria-expanded="true">
            <i class='fa fa-sort-desc pull-right'></i>
          </button>
          <ul id="dropdownMenu{{column.field}}" class="dropdown-menu">
            <div *ngIf="column.type == 'number'">
                <li>
                  <div class="col-sm-9" style="margin-bottom:5px">
                    <input id="number-field-val-{{column.field}}"  min="0" type="number" class="form-control" [ngModel]="numMore" placeholder="Número">
                </div>
                <div class="col-sm-12">
                    <button (click)="searchColumn(column.field, numMore, 'number')"
                                class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                        Pesquisar
                    </button>
                </div>
                </li>
            </div> <div class="clearfix"></div>
            <div *ngIf="column.type == 'date'">
                <li>
                    <div class="col-sm-12" style="margin-bottom:5px">
                        <input id="date-field-val-{{column.field}}"  class="datepicker form-control datepicker-min-field-{{column.field}}" type="date" placeholder="Data" [ngModel]="dateMin">
                    </div>
                    <div class="col-sm-12">
                        <button (click)="searchColumn(column.field, dateMin, 'date')"
                                    class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                            Pesquisar
                        </button>
                    </div>
                </li>
            </div> <div class="clearfix"></div>
            <div *ngIf="column.type == 'text'">
                <li>
                    <div class="col-sm-12">
                      <input id="text-field-val-{{column.field}}" class="form-control" placeholder="Pesquisar"
                      [ngModel]="txtField">
                    </div>
                    <div class="col-sm-12">
                        <button (click)="searchColumn(column.field, txtField)" style="margin-top:5px"
                                    class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                            Pesquisar
                        </button>
                    </div>
                </li>
            </div> <div class="clearfix"></div>
            <div *ngIf="column.type == 'select'">
                <div class="col-sm-12">
                    <select class="form-control" [ngModel]="selectField"
                            id="select-field-val-{{column.field}}" (change)="searchColumnSel(column.field)">
                        <option value="{{dados.val || dados}}" *ngFor="let dados of column.selectFieldsDados">{{dados.name  || dados}}</option>
                    </select>
                </div>
            </div> <div class="clearfix"></div>
          </ul>
        </div>
      <div class="col-filter-result" *ngIf="filterExist" >
        {{colFilterValue(column.field)}}
      </div>
      </th>
      <tr mat-header-row ></tr>
       <td mat-cell *matCellDef="let element"> {{element[column.field]}} </td>
     </ng-container>
     <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columnsBadge">
      <th mat-header-cell  *matHeaderCellDef><div mat-sort-header><b>{{column.header}}</b></div>
        <div class="dropdown pull-right filtro">
          <button class='btn-sm btn btn-default dropdown-toggle' (click)="select(column.field)" data-toggle="dropdown" aria-expanded="true">
            <i class='fa fa-sort-desc pull-right'></i>
          </button>
          <ul id="dropdownMenu{{column.field}}" class="dropdown-menu">
            <div *ngIf="column.type == 'number'">
                <li>
                  <div class="col-sm-9" style="margin-bottom:5px">
                    <input id="number-field-val-{{column.field}}"  min="0" type="number" class="form-control" [ngModel]="numMore" placeholder="Número">
                </div>
                    <div class="col-sm-12">
                        <button (click)="searchColumn(column.field, numMore, 'number')"
                                    class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                            Pesquisar
                        </button>
                    </div>
                </li>
            </div> <div class="clearfix"></div>
            <div *ngIf="column.type == 'date'">
                <li>
                    <div class="col-sm-12" style="margin-bottom:5px">
                        <input class="datepicker form-control datepicker-min-field-{{column.field}}" type="date" placeholder="Apartir de" [ngModel]="dateMin">
                    </div>
                    <div class="col-sm-12" style="margin-bottom:5px">
                        <input class="datepicker form-control datepicker-max-field-{{column.field}}" type="date" placeholder="Até" [ngModel]="dateMax">
                    </div>
                    <div class="col-sm-12">
                        <button (click)="searchColumn(column.field, {min:dateMin,max:dateMax}, 'date')"
                                    class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                            Pesquisar
                        </button>
                    </div>
                </li>
            </div> <div class="clearfix"></div>
            <div *ngIf="column.type == 'text'">
                <li>
                    <div class="col-sm-12">
                      <input id="text-field-val-{{column.field}}" class="form-control" placeholder="Pesquisar"
                      [ngModel]="txtField">
                    </div>
                    <div class="col-sm-12">
                        <button (click)="searchColumn(column.field, txtField)" style="margin-top:5px"
                                    class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                            Pesquisar
                        </button>
                    </div>
                </li>
            </div> <div class="clearfix"></div>
            <div *ngIf="column.type == 'select'">
                <div class="col-sm-12">
                    <select class="form-control" [ngModel]="selectField"
                            id="select-field-val-{{column.field}}" (change)="searchColumnSel(column.field)">
                            <option value="{{dados.val || dados.id}}" *ngFor="let dados of column.selectFieldsDados">{{dados.name  || dados.signified}}</option>
                    </select>
                </div>
            </div> <div class="clearfix"></div>
          </ul>
        </div>
        <div class="col-filter-result" *ngIf="filterExist">
          {{colFilterValue(column.field)}}
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element[column.field] === false" class="badge badge-danger">Não</span>
        <span *ngIf="element[column.field] === true" class="badge badge-success">Sim</span>

        <span *ngIf="element[column.field] === 'ACTIVE'" class="badge badge-success">ACTIVO</span>
        <span *ngIf="element[column.field] === 'INACTIVE'" class="badge badge-danger">INACTIVO</span>
      </td>
    </ng-container>
     <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columnsObject">
       <th mat-header-cell  *matHeaderCellDef><div mat-sort-header><b>{{column.header}}</b></div>
        <div  *ngIf="column.type" class="dropdown pull-right filtro">
          <button class='btn-sm btn btn-default dropdown-toggle' (click)="select(column.object)" data-toggle="dropdown" aria-expanded="true">
            <i class='fa fa-sort-desc pull-right'></i>
          </button>
          <ul id="dropdownMenu{{column.object}}" class="dropdown-menu">
            <div *ngIf="column.type == 'number'">
              <li>
                <div class="col-sm-9" style="margin-bottom:5px">
                  <input id="number-field-val-{{column.field}}"  min="0" type="number" class="form-control" [ngModel]="numMore" placeholder="Número">
              </div>
                  <div class="col-sm-12">
                      <button (click)="searchColumn(column.field, numMore, 'number')"
                                  class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                          Pesquisar
                      </button>
                  </div>
              </li>
          </div> <div class="clearfix"></div>
          <div *ngIf="column.type == 'date'">
              <li>
                  <div class="col-sm-12" style="margin-bottom:5px">
                      <input class="datepicker form-control datepicker-min-field-{{column.field}}" type="date" placeholder="Apartir de" [ngModel]="dateMin">
                  </div>
                  <div class="col-sm-12" style="margin-bottom:5px">
                      <input class="datepicker form-control datepicker-max-field-{{column.field}}" type="date" placeholder="Até" [ngModel]="dateMax">
                  </div>
                  <div class="col-sm-12">
                      <button (click)="searchColumn(column.field, {min:dateMin,max:dateMax}, 'date')"
                                  class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                          Pesquisar
                      </button>
                  </div>
              </li>
          </div> <div class="clearfix"></div>
          <div *ngIf="column.type == 'text'">
              <li>
                  <div class="col-sm-12">
                    <input id="text-field-val-{{column.field}}" class="form-control" placeholder="Pesquisar"
                    [ngModel]="txtField">
                  </div>
                  <div class="col-sm-12">
                      <button (click)="searchColumn(column.field, txtField)" style="margin-top:5px"
                                  class="btn btn-sm btn-default waves-effect waves-light col-sm-12">
                          Pesquisar
                      </button>
                  </div>
              </li>
          </div> <div class="clearfix"></div>
            <div *ngIf="column.type == 'select'">
                <div class="col-sm-12">
                    <select class="form-control" [ngModel]="selectField"
                            id="select-field-val-{{column.object}}" (change)="searchColumnSel(column.object)">
                        <option value="{{dados.id}}" *ngFor="let dados of column.selectFieldsDados">{{dados[column.subObject]}}</option>
                    </select>
                </div>
            </div> <div class="clearfix"></div>
          </ul>
        </div>
      <div class="col-filter-result" *ngIf="filterExist">
        {{colFilterValue(column.object)}}
      </div>
      </th>
       <td mat-cell *matCellDef="let element">
         {{element[column.object] ? element[column.object][column.subObject] : ''}}
        </td>
     </ng-container>

     <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail"
             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div *ngIf="element == expandedElement && element.linkedCategory.length > 0">
            <b class="text-siscomeMed">Subcategorias de {{element.name}}</b>
            <table>
              <tr>
                <th class="customHeader" style="width: 20%;"><b>Nome</b></th>
                <th class="customHeader" style="width: 10%;"><b>Nível</b></th>
                <th class="customHeader" style="width: 15%;"><b>Classificação</b></th>
                <th class="customHeader" style="width: 30%;"><b>Categoria Pai</b></th>
                <th class="customHeader" style="width: 5%;"><b>Estado</b></th>
                <th mat-header-cell style="width: 20%;"><b>Ações</b></th>
              </tr>
              <tr *ngFor="let linha of element.linkedCategory; let x = index">
                <td style="width: 20%;">{{linha.name}}</td>
                <td style="width: 10%;">{{linha.domainLevel.signified}}</td>
                <td style="width: 15%;"><span  class="badge badge-{{linha.risk === 1 ? 'danger': 'success'}}">{{linha.risk === 1 ? 'Alto Risco': 'Baixo Risco'}}</span></td>
                <td style="width: 30%;">{{element.name}}</td>
                <td style="width: 5%;"><span  class="badge badge-{{linha.state === 'INACTIVE' ? 'danger': 'success'}}">{{linha.state === 'INACTIVE' ? 'INACTIVO': 'ACTIVO'}}</span></td>
                <td style="width: 20%;">
                  <a  title="Ver" *ngIf="options.view" mat-icon-button aria-label="View" color="primary" (click)="view(linha)">
                    <mat-icon aria-hidden="false" aria-label="View" >remove_red_eye</mat-icon>
                  </a>
                  <a  title="Editar" *ngIf="options.edit" mat-icon-button aria-label="Edit" label="Edit" color="primary" (click)="edit(linha)">
                    <mat-icon aria-hidden="false">edit</mat-icon>
                  </a>
                  <a title="Activar" *ngIf="options.statusUpdate && linha.status === 'INACTIVE'" mat-icon-button aria-label="Activar" color="primary" (click)="changeStatus(linha)">
                   <mat-icon>check_circle_outline</mat-icon>
                 </a>
                 <a title="Desactivar" *ngIf="options.statusUpdate && linha.status === 'ACTIVE'" mat-icon-button aria-label="Desactivar" color="warn" (click)="changeStatus(linha)">
                   <mat-icon>remove_circle_outline</mat-icon>
                 </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </td>
    </ng-container>

     <ng-container matColumnDef="actions">
      <th mat-header-cell  *matHeaderCellDef><b>Ações</b>
      </th>
       <td mat-cell  *matCellDef="let element; let i = index;">
         <div class="row">
         <a  title="Ver" *ngIf="options.view" mat-icon-button aria-label="View" color="primary" (click)="view(element)">
          <mat-icon aria-hidden="false" aria-label="View" >remove_red_eye</mat-icon>
        </a>
        <a  title="Editar" *ngIf="options.edit" mat-icon-button aria-label="Edit" label="Edit" color="primary" (click)="edit(element)">
          <mat-icon aria-hidden="false">edit</mat-icon>
        </a>
        <a title="Activar" *ngIf="options.statusUpdate && element.state === 'INACTIVE'" mat-icon-button aria-label="Activar" color="primary" (click)="changeStatus(element)">
         <mat-icon>check_circle_outline</mat-icon>
       </a>
       <a title="Desactivar" *ngIf="options.statusUpdate && element.state === 'ACTIVE'" mat-icon-button aria-label="Desactivar" color="warn" (click)="changeStatus(element)">
         <mat-icon>remove_circle_outline</mat-icon>
       </a>
        <a title="Apagar" *ngIf="options.delete" mat-icon-button aria-label="Delete" color="warn" (click)="delete(dataSource, element, i)">
          <mat-icon aria-hidden="false" >delete</mat-icon>
        </a>
        <a *ngFor="let custom of options.custom" title="{{custom.title}}" mat-icon-button color="primary" (click)=custom.fn(element) >
          <mat-icon aria-hidden="false" >{{custom.icon}}</mat-icon>
        </a>
         </div>
       </td>
     </ng-container>

     <tr mat-header-row *matHeaderRowDef="displayedColumns; let i = index;"></tr>
     <tr mat-row *matRowDef="let element; columns: displayedColumns;"
         class="example-element-row"
         [class.example-expanded-row]="expandedElement === element"
         (click)="expandedElement = expandedElement === element ? null : element">
     </tr>
     <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
  <mat-paginator [length]="resultsLength" [pageSize]="20" (page)="loadData(false, queryDefaults)" showFirstLastButtons></mat-paginator>
</div>
