import { Injectable } from '@angular/core';
import { AddEmployComponent } from './add-employ.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class AddEmployService {
  constructor(private modalService: NgbModal) { }

  public add(
    title: string,
    entrada: any,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancelar',
    dialogSize: 'lg'|'sm'| 'md' = 'lg'): Promise<any> {
    const modalRef = this.modalService.open(AddEmployComponent, { size: dialogSize , backdrop: 'static',
    keyboard: false});
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.entrada = entrada;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }
  public close(): void{
    this.modalService.dismissAll(AddEmployComponent);
  }
}
