<div class="container-fluid">
  <h2 class="text-siscomeTot underline">{{ page.name === 'edit' ? 'Editar' : page.name === 'view' ? 'Visualizar' :'Criar' }} País</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="paisForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit(page.name)">
        <div class="row">
          <mat-form-field appearance="outline" class="form-group col-4">
            <mat-label class="text-siscomeMed">Nome</mat-label>
            <mat-hint class="text-siscomeMed">Nome da pais</mat-hint>
            <input class="form-control" [(ngModel)]="pais.name" [ngClass]="{ 'is-invalid': submitted && paisForm.controls['name'].hasError('required' || 'invalid') }"
             id="name" matInput placeholder="Nome da pais" required
              name="name" formControlName="name">
              <div class="invalid-feedback" *ngIf="paisForm.controls['name'].hasError('required')">
                o Campo é Obrigatório
              </div>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="center">
      <button mat-raised-button *ngIf="page.name !== 'view'" class="siscomeTot large-button"  color="primary" (click)="onSubmit(page.name)">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ page.name === 'edit' ? 'Guardar' : 'Criar' }}</button>
        <button mat-raised-button *ngIf="page.name === 'view'"   class="siscomeTot" (click)="editar()" color="grey">Editar
        </button>
        <button mat-raised-button   class="grey" (click)="cancelar()" color="grey">{{ page.name === 'create' ? 'Cancelar' : 'Voltar' }}
        </button>
    </mat-card-actions>
  </mat-card>
</div>
