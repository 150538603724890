import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/services/country.service';
import { CityService } from 'src/app/services/city.service';
import { ZoneService } from 'src/app/services/zone.service';

@Component({
  selector: 'app-create-zone',
  templateUrl: './create-zone.component.html',
  styleUrls: ['./create-zone.component.css']
})
export class CreateZoneComponent implements OnInit {
  zone: any = {
    city: {}
  };
  country;
  paises;
  cidades: any = [];
  zoneForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl = '/zonas';
    error = '';
    page: any = {base: null, name: null};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private cityService: CityService,
    private toast: ToastrService,
    private countryService: CountryService,
    private zoneService: ZoneService) {
      this.countryService.getAll(null).pipe(first())
                .subscribe(
                    data => {
                      console.log('data', data);
                      this.paises = data['content'];
                    },
                    error => {
                      console.log('error', error);
                  });
    }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.zoneService.get(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.zone = data;
                this.country = this.zone.city.country;
                this.getCities(this.zone.city.country);
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.zoneForm = this.fb.group({
        name: new FormControl(null, Validators.required),
        country: new FormControl(null, Validators.required),
        city: new FormControl(null, Validators.required)
    });
    if (this.page.name === 'view')
    this.zoneForm.disable();

    }

      onSubmit(page): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.zoneForm.invalid) {
          console.log('invaid', this.zoneForm);
          return;
        }
        console.log('valid', this.zone);
        this.loading = true;

        if (page === 'edit'){
          this.zoneService.update(this.zone, this.zone.id)
            .subscribe(
                data => {
                  this.toast.success('Editaste uma Zona com sucesso', 'Edição', { positionClass: 'toast-top-center' });
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });

        }
        else if (page === 'create'){
          this.zoneService.create(this.zone)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Criaste uma Zona com sucesso', 'Criação', { positionClass: 'toast-top-center' });
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  this.loading = false;
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.zoneForm.enable();
    }

    getCities(pais): void{
      if(pais)
        this.cidades = pais.cityList;
    }

    equals(o1: any, o2: any) {
      if(o1 && o2)
      return o1.id === o2.id;
      else return false
   }

}

