import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import { KeycloakAuthService } from 'src/app/services/keycloak.service';
import { ConfirmationDialogService } from 'src/app/components/confirmation-dialog/confirmation-dialog.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { LevelService } from 'src/app/services/level.service';

@Component({
  selector: 'app-create-level',
  templateUrl: './create-level.component.html',
  styleUrls: ['./create-level.component.css']
})
export class CreateLevelComponent implements OnInit {


  level: any = {
    store: {},
  };

  lojas;
  tipos;

  levelForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl = '/levels';
  error = '';
  page: any = {base: null, name: null};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastrService,
    protected authService: KeycloakAuthService,
    private confirmationDialogService: ConfirmationDialogService,
    private levelService: LevelService,
    private uploadService: FileUploadService,
    private storeService: StoreService)  {
      // this.storeService.getAll(null).pipe(first())
      // .subscribe(
      //     data => {
      //       console.log('lojas', data);
      //       this.lojas = data['content'];
      //     },
      //     error => {
      //       console.log('error', error);
      //   });
    }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.levelService.get(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.level = data;
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.levelForm = this.fb.group({
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        // loja: new FormControl(null),
        minOrderValue: new FormControl('', Validators.required),
        conditionValue: new FormControl('', Validators.required),
        photo: new FormControl(null),
        rewardCode: new FormControl('', Validators.required),
        rewardName: new FormControl('', Validators.required),
        rewardValue: new FormControl('', Validators.required),
    });
    if (this.page.name === 'view')
    this.levelForm.disable();

    }

      onSubmit(page): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.levelForm.invalid) {
          console.log('invaid', this.levelForm);
          return;
        }
        console.log('valid', this.level);
        this.loading = true;

        if (page === 'edit'){
          this.levelService.update(this.level, this.level.id)
            .subscribe(
                data => {
                  this.toast.success('Editaste um level com sucesso', 'Edição', { positionClass: 'toast-top-center' });
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });

        }
        else if (page === 'create'){
          this.levelService.create(this.level)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Criaste um level com sucesso', 'Criação', { positionClass: 'toast-top-center' });
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  this.loading = false;
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.levelForm.enable();
    }

}
