import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute  } from '@angular/router';


@Component({
  selector: 'app-add-employ',
  templateUrl: './add-employ.component.html',
  styleUrls: ['./add-employ.component.css']
})
export class AddEmployComponent implements OnInit {

  @Input() title: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() entrada: any;

  formulario: FormGroup;
  empregado: any = {};
  submitted = false;

  constructor(private activeModal: NgbActiveModal, private route: ActivatedRoute,
              private fb: FormBuilder,
              private router: Router) { }



  ngOnInit(): void {
    this.empregado = Object.assign({}, this.entrada);
    this.formulario = this.fb.group({
      name: new FormControl(null, Validators.required),
      birthdate: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
  });
  }

  get f(): any { return this.formulario.controls; }



  addEmpregado(item): any {
    this.submitted = true;

    if (this.formulario.invalid) {
      console.log('formulario', this.formulario);
      if(this.f.name.errors){
        $("#name").focus();
      }
      else if(this.f.birthdate.errors){
        $("#birthdate").focus();
      }
      else if(this.f.address.errors){
        $("#address").focus();
      }
      else if(this.f.phone.errors){
        $("#phone").focus();
      }
      return;
    }
    if (item) {
        this.activeModal.close(item);
    }
}

  public cancelar(): void {
    this.activeModal.dismiss();
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }

  checkPhone(event, field): void{
    if(field){
      var valor = field.toString();
      console.log(valor);
      console.log(valor.length);
      if(valor.length === 3 || valor.length === 7)
      this.empregado.phone = valor + '-';
      console.log(valor + '-');
    }
  }
}
