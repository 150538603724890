<div class="container-fluid">
  <h2 class="text-siscomeTot underline">{{ page.name === 'edit' ? 'Editar' : page.name === 'view' ? 'Visualizar' :'Criar' }} Cidade</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="cityForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit(page.name)">
        <div class="row">
          <mat-form-field appearance="outline" class="form-group col-8">
            <mat-label class="text-siscomeMed">Nome</mat-label>
            <mat-hint class="text-siscomeMed">Nome da Cidade</mat-hint>
            <input class="form-control" [(ngModel)]="city.name" [ngClass]="{ 'is-invalid': submitted && cityForm.controls['name'].hasError('required' || 'invalid') }"
             id="name" matInput placeholder="Nome da city" required
              name="name" formControlName="name">
              <div class="invalid-feedback" *ngIf="cityForm.controls['name'].hasError('required')">
                o Campo é Obrigatório
              </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-group col-4">
            <mat-label class="text-siscomeMed">País</mat-label>
            <mat-hint class="text-siscomeMed">País onde fica a Cidade</mat-hint>
            <select matNativeControl  [(ngModel)]="city.country.id"  id="country" [ngClass]="{ 'is-invalid': submitted && cityForm.controls['country'].hasError('required' || 'invalid') }"
              name="country" formControlName="country" required>
              <option *ngFor="let country of paises; let i = index;" [selected]="country.id === city.country.id" label="{{country.name}}"  [value]="country.id"></option>
              </select>
              <div class="invalid-feedback" *ngIf="cityForm.controls['country'].hasError('required')">
                o Campo é Obrigatório
              </div>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="center">
      <button mat-raised-button *ngIf="page.name !== 'view'" class="siscomeTot large-button"  color="primary" (click)="onSubmit(page.name)">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ page.name === 'edit' ? 'Guardar' : 'Criar' }}</button>
        <button mat-raised-button *ngIf="page.name === 'view'"   class="siscomeTot" (click)="editar()" color="grey">Editar
        </button>
        <button mat-raised-button   class="grey" (click)="cancelar()" color="grey">{{ page.name === 'create' ? 'Cancelar' : 'Voltar' }}
        </button>
    </mat-card-actions>
  </mat-card>
</div>
