<div class="container-fluid">
  <h2 class="text-siscomeTot underline">{{ page.name === 'edit' ? 'Editar' : page.name === 'view' ? 'Visualizar' :'Criar' }} Seguimento</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="seguimentoForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit(page.name)">
        <div class="row">
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Loja</mat-label>
            <select matNativeControl  [(ngModel)]="seguimento.store.storeId"  id="loja"
             [ngClass]="{ 'is-invalid': submitted && seguimentoForm.controls['loja'].hasError('required' || 'invalid') }"
              name="loja" formControlName="loja" >
              <option *ngFor="let store of lojas; let i = index;" [selected]="store.storeId === seguimento.store.storeId" label="{{store.name}} {{store.identifier}}"  [value]="store.storeId"></option>
              </select>
              <div class="invalid-feedback" *ngIf="seguimentoForm.controls['loja'].hasError('required')">
                o Campo é Obrigatório
              </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Tipo de Seguimento</mat-label>
            <select matNativeControl  [(ngModel)]="seguimento.segmentType"  id="tipo"
             [ngClass]="{ 'is-invalid': submitted && seguimentoForm.controls['tipo'].hasError('required' || 'invalid') }"
              name="tipo" formControlName="tipo" >
              <option *ngFor="let type of tipos; let i = index;" [selected]="type.id === seguimento.segmentType.id" label="{{type.name}}"   [ngValue]="type"></option>
              </select>
              <div class="invalid-feedback" *ngIf="seguimentoForm.controls['tipo'].hasError('required')">
                o Campo é Obrigatório
              </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Nome</mat-label>
            <mat-hint class="text-siscomeMed">Nome do seguimento</mat-hint>
            <input class="form-control" [(ngModel)]="seguimento.name" id="name" matInput placeholder="Nome do seguimento" required
              name="name" formControlName="name">
              <div class="invalid-feedback" *ngIf="seguimentoForm.controls['name'].hasError('required')">
                o Campo é Obrigatório
              </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label>Descrição</mat-label>
            <textarea class="form-control" [(ngModel)]="seguimento.description" id="description" matInput placeholder="Descrição sobre a seguimento" required
              name="description" formControlName="description"></textarea>
            <div class="invalid-feedback" *ngIf="seguimentoForm.controls['description'].hasError('required')">
              o Campo é Obrigatório
            </div>
            <mat-hint>Descrição das informações do seguimento</mat-hint>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="seguimento.segmentType.typesSegment === 'ANIVERSARY'">
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Dias</mat-label>
            <mat-hint class="text-siscomeMed">Margem d dias de aniversário</mat-hint>
            <input type="number" class="form-control" [(ngModel)]="seguimento.aniversary.days" id="days" matInput placeholder="Dias" required
              name="days" formControlName="days">
              <div class="invalid-feedback" *ngIf="seguimentoForm.controls['days'].hasError('required')">
                o Campo é Obrigatório
              </div>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="center">
      <button mat-raised-button *ngIf="page.name !== 'view'" class="siscomeTot large-button"  color="primary" (click)="onSubmit(page.name)">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ page.name === 'edit' ? 'Guardar' : 'Criar' }}</button>
        <button mat-raised-button *ngIf="page.name === 'view'"   class="siscomeTot" (click)="editar()" color="grey">Editar
        </button>
        <button mat-raised-button   class="grey" (click)="cancelar()" color="grey">{{ page.name === 'create' ? 'Cancelar' : 'Voltar' }}
        </button>
    </mat-card-actions>
  </mat-card>
</div>
