import { Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { KeycloakAuthService } from 'src/app/services/keycloak.service';
import { first } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Observable, Subscription } from 'rxjs';




@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TabelaComponent implements OnInit  {

  private eventsSubscription: Subscription;

   // @Input() ELEMENT_DATA: string[];
   @Input() events: Observable<any>;
   selection = new SelectionModel<any>(true, []);
   @Input() Service: any;

   @Input() search: any;

   @Input() labelsColumns: string[];

   @Input() displayedColumns: string[];

   @Input() columnsToDisplay: any[];

   @Input() columnsObject: any[];

   @Input() columnsObject2: any[];

   @Input() columnsObject3: any[];

   @Input() columnsBadge: any[];

   @Input() title: string;

   @Input() novo: any;

   @Input() buttons: any[];

   @Input() filtro: false;

   @Input() operador: false;

   @Input() options: {edit: false, view: false, delete: false, statusUpdate: false, select: false, expand:false, custom: []};

   @Input() view: (args: any) => void;

   @Input() edit: (args: any) => void;

   @Input() delete: (args: any) => void;

   @Input() changeStatus: (args: any) => void;

  dataSource: any;
  expandedElement= null;
  pesquisar = null;
  languages;
  filtrosObjectos = [];
  queryDefaults: any = {};
  resultsLength = 0;
  numMore: number = null;
  numLess: number  = null;
  dateMin: any  = null;
  dateMax: any  = null;
  selectField: any  = null;
  txtField: string  = null;
  isLoadingResults = true;
  isRateLimitReached = false;
  filterExist = false;
  searchFilters = {
    submit: this.searchFilterSubmit,
    query: {
        size: 20
    },
    values: {
        selectFields: {},
        numericFields: {},
        dateFields: {}
    }
};
tecnico = false;
diretor = false;
admin = false;
user = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private http: HttpClient,
    protected authService: KeycloakAuthService,) {
      if(this.authService.currentUserValue){
        console.log('utilizador', this.authService.currentUserValue);
      }
    }

  ngOnInit(): void {
    if(this.events)
    this.eventsSubscription = this.events.subscribe((x) => {
      this.isLoadingResults = true;
      this.dataSource = null;
      console.log('new data', x)
      setTimeout(() => {
      this.dataSource = x;
      this.resultsLength = this.resultsLength - 1;
      this.isLoadingResults = false;
    }, 1000);

    });
    //let query = {sort: 'createdAt,DESC', page: 0};
    let query = { page: 0};
    if(this.operador){
      query['userInsert']= this.authService.currentUserValue._userProfile.email;
    }
    this.Service(query).pipe(first())
     .subscribe(
         data => {
          console.log('data ', data);
          this.dataSource = data['content'];
          console.log('this.dataSource ', this.dataSource );
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['totalElements'];
         },
         error => {
             console.log('error', error);
         });
    console.log('this.queryDefaults ', this.queryDefaults );
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.forEach(row => this.selection.select(row));
    }

  sortData(): void {
    console.log('this.sort.Active', this.sort.active);
    console.log('this.sort.direction', this.sort.direction);
    const query = {sort: '' + this.sort.active + ',' + this.sort.direction + '', page: 0};
    this.loadData(true, query);
}

searchFilterSubmit(): void {
  console.log('searchFilters', this.searchFilters);
  this.queryDefaults = Object.assign(this.queryDefaults, this.searchFilters.values);
  this.loadData(true, this.queryDefaults);
}
colFilterValue(k): any {
let result = '';
const values = this.searchFilters.values;
let capitalizeFirst = false;
if (values[k]) {
    result = values[k];
}
else if (values.selectFields[k]) {
    result = values.selectFields[k].label;
} else if (values.numericFields[k]) {
    if (values.numericFields[k].more) {
        result += 'A partir de ' + values.numericFields[k].more + ' ';
    }
    if (values.numericFields[k].less) {
        result += 'até ' + values.numericFields[k].less;
    }
    capitalizeFirst = true;
} else if (values.dateFields[k]) {
    if (values.dateFields[k].min) {
        result += 'A partir de ' + values.dateFields[k].min + ' ';
    }
    if (values.dateFields[k].max) {
        result += 'até ' + values.dateFields[k].max;
    }
    capitalizeFirst = true;
}
return capitalizeFirst ? result.charAt(0).toUpperCase() + result.slice(1) : result;
}

searchColumnSel(k): void {
  this.queryDefaults.page = 0;
  //this.queryDefaults.sort = 'createdAt,DESC';
  //const selector = ('#select-field-val-' + k + ' option:selected').replaceAll('.', '\\.');
  const selector = ('#select-field-val-' + k + ' option:selected').split('.').join('\\.');
  const el = $(selector);
  this.searchFilters.values.selectFields[k] = {val: el.val(), label: el.text().trim()};
  console.log('searchFilters', this.searchFilters);
  this.queryDefaults[k] =  el.val();
  console.log('queryDefaults', this.queryDefaults);
  $('#dropdownMenu' + k).toggle();
  this.filterExist = true;
  this.loadData(true, this.queryDefaults);

  // this.searchFilters.submit();
}
select(k): void{
console.log('campo', k);
$('#dropdownMenu' + k).toggle();
}
searchColumn(k, v, type = null): void {
  console.log('v', v);
  this.filterExist = true;
  this.queryDefaults.page = 0;
  //this.queryDefaults.sort = 'createdAt,DESC';

  if (type == null){
    const selector = ('#text-field-val-' + k ).replace('.', '\\.');
    const el = $(selector);
    this.searchFilters.values[k] = el.val();
    this.queryDefaults[k] =  el.val();
  }
  else if (type === 'date') {
      // fix getting date value
      const selector = ('#date-field-val-' + k ).replace('.', '\\.');
      const el = $(selector);
      this.searchFilters.values.dateFields[k] = el.val();
      this.queryDefaults[k] = el.val();
  } else if (type === 'number'){
    const selector = ('#number-field-val-' + k ).replace('.', '\\.');
    const el = $(selector);
    this.searchFilters.values.numericFields[k] = el.val();
    this.queryDefaults[k] =  el.val();
  }
  console.log('k', k);
  $('#dropdownMenu' + k).toggle();
  $('#dropdownMenu' + v).toggle();
  this.loadData(true, this.queryDefaults);
}


  loadData(search = false, query): void {
    console.log('query', query);
    if (search === false){
    //  query.sort = this.sort.active ? '' + this.sort.active + ',' + this.sort.direction + '' : 'createdAt,DESC';
      query.page = this.paginator.pageIndex;
    }
    if(this.operador){
      query['operator.emailResponsible']= this.authService.currentUserValue._userProfile.email;
    }
    this.isLoadingResults = true;
    this.Service(query).pipe(first())
     .subscribe(
         data => {
          this.dataSource = data['content'];
          console.log('this.dataSource ', this.dataSource );
          this.dataSource.paginator = this.paginator;
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['totalElements'];
         },
         error => {
             console.log('error', error);
             this.isRateLimitReached = true;
         });
}

reset(): void{
  this.filterExist = false;
  this.pesquisar = null;
  this.numMore = null;
  this.numLess= null;
  this.dateMin = null;
  this.dateMax = null;
  this.selectField = null;
  this.txtField = null;

  this.queryDefaults = {};
  this.searchFilters = {
    submit: this.searchFilterSubmit,
    query: {
        size: 20
    },
    values: {
        selectFields: {},
        numericFields: {},
        dateFields: {}
    }
};
  this.loadData(false, {page: 0});

}


    drop(event: CdkDragDrop<string[]>): void {
      moveItemInArray(this.labelsColumns, event.previousIndex, event.currentIndex);
    }

  applyFilter(keyword: string): void {

    if (/\S/.test(keyword)){
      this.search(keyword).pipe(first())
     .subscribe(
         data => {
          console.log('this.dataSource ', data );
          this.dataSource = data['content'];
          console.log('this.dataSource ', this.dataSource );
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['totalElements'];
         },
         error => {
             console.log('error', error);
         });
    }
    else{
      this.loadData(false, {page: 0});
    }
  }

}
