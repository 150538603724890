import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from 'src/app/components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-cliente',
  templateUrl: './list-cliente.component.html',
  styleUrls: ['./list-cliente.component.css']
})
export class ListClienteComponent implements OnInit {

  eventsSubject: Subject<any> = new Subject<any>();

  clientes: any[];
  tipos: any =[];
  displayedColumns: string[] = ['firstName', 'lastName', 'email','gender', 'birthDate', 'phone', 'status', 'actions'];
  imageColumn = [ {field: 'photo', header: 'Logo'}]
  columnsToDisplay = [{field: 'id', header: 'Id', type: 'number'},{field: 'firstName', header: 'Nome', type: 'text'},
   {field: 'lastName', header: 'Apelido', type: 'text'}, {field: 'phone', header: 'Telefone', type: 'text'},
    {field: 'birthDate', header: 'Data Nasc', type: 'date'}, {field: 'email', header: 'Email', type: 'text'}];
    columnsBadge = [{field: 'status', header: 'Estado', type: 'select', selectFieldsDados:  [ {name: 'Ativo', val: 'ACTIVE'}, {name: 'Inativo', val: 'INACTIVE'}]},
    {field: 'gender', header: 'Sexo', type: 'select', selectFieldsDados: [ {name: 'Masculino', val: 'm'}, {name: 'Feminino', val: 'f'}]}];
    options = {edit: false, view: true, delete: false, statusUpdate: false};
  title = 'Clientes';
  //novo = {link: '/clientes/criar', nome: 'Novo cliente'};
  page: any = {base: null, name: null};
  service;

  constructor(private customerService: CustomerService,
    private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    private toast: ToastrService,
    private router: Router,) {
this.service = this.customerService.getAll;
}

ngOnInit(): void {
  this.page.base = this.route.data['value'].base;
  this.page.name = this.route.data['value'].routeName;
  }

  edit = (args: any): void => {
    console.log(args);
    this.router.navigate([this.page.base  + '/editar'], { queryParams: { id: args.id } });
    }
    view = (args: any): void => {
      console.log(args);
      this.router.navigate([this.page.base  + '/view'], { queryParams: { id: args.id } });
    }
    changeStatus = (args: any): void => {
    let textConfirm = '';
    let status = '';
    let textShow = '';

    let service;
    if (args.status === 'ACTIVE'){
      service = this.customerService.inactive(args.id);
      textConfirm = 'Pretende Desactivar o cliente ' + args.firstName + ' ' +args.lastName +'? Ao desactivar o cliente o estado do cliente será alterado para INATIVO.';
      status = 'INACTIVE';
      textShow = 'Cliente DESATIVADO COM SUCESSO';
    }
    else{
      service = this.customerService.active(args.id);
      textConfirm = 'Pretende Activar o cliente ' + args.firstName + ' ' +args.lastName +'? Ao activar o cliente o estado do cliente será alterado para ATIVO';
      status = 'ACTIVE';
      textShow = 'Cliente ACTIVADO COM SUCESSO';
    }

    this.confirmationDialogService.confirm('Por favor confirme', textConfirm)
    .then((confirmed) =>
    {
      if (confirmed){
        service.subscribe((event: any) => {
          this.toast.warning(textShow, 'Mudança de Estado');
          args.status = status;
      });
      }
    }
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

    delete = (total: any, args: any, index: number): void => {
      this.confirmationDialogService.confirm('Por favor confirme', 'Pretende apagar este cliente '+ args.firstName + ' ' +args.lastName + '? Ao eliminar os dados do cliente serão perdidos!!')
      .then((confirmed) =>
      {
        if (confirmed){
          const indexo = total.indexOf(args);
          this.customerService.delete(args.id).pipe(first())
      .subscribe(
          data => {
            this.toast.warning('Eliminado com sucesso', 'Delete');
            total.splice(indexo, 1);
            console.log('total', total);
            this.eventsSubject.next(total);
          },
          error => {
              console.log('error', error);
          });
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
      console.log(args);
    }

  }
