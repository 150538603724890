import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/services/country.service';
import { CityService } from 'src/app/services/city.service';

@Component({
  selector: 'app-create-city',
  templateUrl: './create-city.component.html',
  styleUrls: ['./create-city.component.css']
})
export class CreateCityComponent implements OnInit {

  city: any = {
    country: {}
  };
  paises;
  cityForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl = '/cidades';
    error = '';
    page: any = {base: null, name: null};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private cityService: CityService,
    private toast: ToastrService,
    private countryService: CountryService) {
      this.countryService.getAll(null).pipe(first())
                .subscribe(
                    data => {
                      console.log('data', data);
                      this.paises = data['content'];
                    },
                    error => {
                      console.log('error', error);
                  });
    }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.cityService.get(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.city = data;
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.cityForm = this.fb.group({
        name: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required)
    });
    if (this.page.name === 'view')
    this.cityForm.disable();

    }

      onSubmit(page): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.cityForm.invalid) {
          console.log('invaid', this.cityForm);
          return;
        }
        console.log('valid', this.city);
        this.loading = true;

        if (page === 'edit'){
          this.cityService.update(this.city, this.city.id)
            .subscribe(
                data => {
                  this.toast.success('Editaste uma Cidade com sucesso', 'Edição', { positionClass: 'toast-top-center' });
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });

        }
        else if (page === 'create'){
          this.cityService.create(this.city)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Criaste uma Cidade com sucesso', 'Criação', { positionClass: 'toast-top-center' });
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  this.loading = false;
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.cityForm.enable();
    }

}

