import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpErrorResponse  } from '@angular/common/http';
import { environment } from './../../environments/environment';
import {Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

getAll(): any {
    return this.http.get<any>(API_URL + `files`);
}
// tslint:disable-next-line: typedef
get(id, idFile): Observable<any> {
  return this.http.get(API_URL + 'stores/'+ id +'/files/' + idFile, { responseType: 'blob' });
}

getStoreFiles(id): Observable<any> {
  return this.http.get(API_URL + 'stores/'+ id +'/files', { responseType: 'blob' });
}

viewFile(id, type): Observable<any> {
      return this.http.get(API_URL + 'stores/'+ id +'/files/' + type , { responseType: 'blob' });
}
viewGaleryFile(id, idGallery): Observable<any> {
  return this.http.get(API_URL + 'stores/'+ id +'/files/' + idGallery  + '/gallery', { responseType: 'blob' });
}
getLevelFile(id, idFile): Observable<any> {
  return this.http.get(API_URL + 'levels/'+ id +'/files/' + idFile, { responseType: 'blob' });
}

viewLevelFile(id, idFile): Observable<any> {
      return this.http.get(API_URL + 'levels/'+ id +'/files/' + idFile + '/view', { responseType: 'blob' });
}



  uploadFile(file, id, typeFile): any{
    return this.http
    .post(API_URL + 'stores/' + id + '/files/' + typeFile , file)
    .pipe(
      map(res => {
        return res;
      }),
    );
  }
  uploadMultiFile(file, id): any{
    return this.http
    .post(API_URL + 'stores/' + id + '/files/mult-files' , file)
      .pipe(map(res => {
        return res;
    }));
  }

  uploadLevelFile(file, id, typeFile): any{
    return this.http
    .post(API_URL + 'levels/' + id + '/files/' + typeFile , file)
    .pipe(map(res => {
      return res;
  }));
  }
  uploadMultiLevelFile(file,type, id): any{
    return this.http
    .post(API_URL + 'levels/' + id + '/files/' + type + '/mult-files' , file)
      .pipe(map(res => {
        return res;
    }));
  }
  deleteStoreFile(id, type): any{
    return this.http
    .delete(API_URL + 'stores/' + id  + '/files/' + type )
      .pipe(map(res => {
        return res;
    }));
  }
  deleteStoreGalleryFile(id, idGallery): any{
    return this.http
    .delete(API_URL + 'stores/' + id  + '/files/' + idGallery + '/gallery')
      .pipe(map(res => {
        return res;
    }));
  }
}
