import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { RewardService } from 'src/app/services/reward.service';
import { StoreService } from 'src/app/services/store.service';
import { EarningRulesService } from 'src/app/services/earning-rules.service';
import { SegmentService } from 'src/app/services/segment.service';
import { LevelService } from 'src/app/services/level.service';

@Component({
  selector: 'app-create-premios',
  templateUrl: './create-premios.component.html',
  styleUrls: ['./create-premios.component.css']
})
export class CreatePremiosComponent implements OnInit {
  premio: any = {
    store: {},
    rewardType: {},
    levels_: [],
    segments_: [],
    cashBack: {}
  };
  tipos = [];
  lojas;
  levels;
  levelsList = [];
  segmentsList = [];
  segments;
  targets;
  items;
  items2;
  premioForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl = '/premios';
  error = '';
  page: any = {base: null, name: null};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastrService,
    private  earningRulesService: EarningRulesService,
    private segmentService: SegmentService,
    private levelService: LevelService,
    private storeService: StoreService,
    private rewardService: RewardService,)  {
      this.storeService.getAll({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            console.log('lojas', data);
            this.lojas = data['content'];
          },
          error => {
            console.log('error', error);
        });
        this.rewardService.getAllTypes({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            console.log('tipos', data);
            this.tipos = data['content'];
          },
          error => {
            console.log('error', error);
        });
                this.earningRulesService.getAllTarget({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            console.log('targets', data);
            this.targets = data;
          },
          error => {
            console.log('error', error);
        });
    }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.rewardService.get(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.premio = data;
                this.getTipos(this.premio.targetTypeEnum, true);
                this.premio.endDate = this.setValue(this.premio.endDate);
                this.premio.startDate = this.setValue(this.premio.startDate);
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.premioForm = this.fb.group({
        loja: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        conditionDescription: new FormControl('', Validators.required),
        endDate: new FormControl('', Validators.required),
        limitClient: new FormControl('', Validators.required),
        limitValue: new FormControl('', Validators.required),
        points: new FormControl('', Validators.required),
        startDate: new FormControl('', Validators.required),
        tipo: new FormControl('', Validators.required),
        target: new FormControl('', Validators.required),
        pointValue: new FormControl('', Validators.required),
        levels: this.fb.array([ ]),
        segments: this.fb.array([ ])
    });
    if (this.page.name === 'view')
    this.premioForm.disable();

    }

      onSubmit(page): void {
        this.submitted = true;

        if(this.premio.targetTypeEnum === 'LEVEL'){
          this.premioForm.controls['segments'].setErrors(null);
          this.premio.levels_ = [];
        this.levelsList.forEach( (item: any, i: number  )  => {
          if (item === true) {
            this.premio.levels_.push(this.levels[i]);
          }
        });
          if(this.premio.levels_.length === 0){
            this.premioForm.controls['levels'].setErrors({'required': true});
          }
          else{
            this.premioForm.controls['levels'].setErrors(null);
          }
        }
        else if (this.premio.targetTypeEnum === 'SEGMENT'){
          this.premioForm.controls['levels'].setErrors(null);
          this.premio.segments_ = [];
          this.segmentsList.forEach( (item: any, i: number  )  => {
            if (item === true) {
              this.premio.segments_.push(this.segments[i]);
            }
          });
          if(this.premio.segments_.length === 0){
            this.premioForm.controls['segments'].setErrors({'required': true});
          }
          else{
            this.premioForm.controls['segments'].setErrors(null);
          }
        }

        // stop here if form is invalid
        if (this.premioForm.invalid) {
          console.log('invaid', this.premioForm);
          return;
        }

        console.log('start', this.premio.startDate);
        console.log('end', this.premio.endDate);
        this.premio.endDate = this.getValue( this.premio.endDate);
        this.premio.startDate = this.getValue(this.premio.startDate);
        console.log('valid', this.premio);
        this.loading = true;

        if (page === 'edit'){
          this.rewardService.update(this.premio, this.premio.id)
            .subscribe(
                data => {
                  this.toast.success('Editaste um  premio com sucesso', 'Edição', { positionClass: 'toast-top-center' });
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    if(this.premio.endDate)
                    this.premio.endDate = this.setValue( this.premio.endDate);
                    if(this.premio.startDate)
                    this.premio.startDate = this.setValue(this.premio.startDate);
                    this.loading = false;
                });

        }
        else if (page === 'create'){
          this.rewardService.create(this.premio)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Criaste um  premio com sucesso', 'Criação', { positionClass: 'toast-top-center' });
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  if(this.premio.endDate)
                    this.premio.endDate = this.setValue( this.premio.endDate);
                    if(this.premio.startDate)
                    this.premio.startDate = this.setValue(this.premio.startDate);
                  this.loading = false;
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.premioForm.enable();
    }

    setValue(date): any {
      var isoString = date.toString()
      isoString = isoString.replace(" ", "T");
      return isoString
  }
  getValue(date): any {
    var isoString = date.toString()
    if(isoString.includes("T")){
      isoString = isoString.replace("T", " ");
    }
    console.log('length', isoString.length)
    if(isoString.length === 16){
      isoString = isoString + ':00';
    }
    return isoString
}

getAvailableLevels(): FormGroup {
  return this.fb.group({
    level: false,
  });
}

getLevels(edit): any{
  this.items = this.premioForm.get('levels') as FormArray;
  this.levelService.getAll({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            this.levels = data['content'];
            if ( typeof this.levels !== 'undefined'){
              this.levels.forEach((level: any, i: number ) => {
                this.items.push(this.getAvailableLevels());
                if(edit){
                  this.premio.levels_.forEach((type: any, x: number ) => {
                    if(level.id  === type.id)
                    this.levelsList[i] = true;
                  });
                }
              });
            }
            console.log('items ants', this.items);
          },
          error => {
            console.log('error', error);
        });
}

getAvailableSegments(): FormGroup {
  return this.fb.group({
    segment: false,
  });
}
getTipos(type, edit): any {
  if(type === 'LEVEL')
  this.getLevels(edit);
  else if(type === 'SEGMENT')
  this.getSegments(edit);
}

getSegments(edit): any{
  this.items2 = this.premioForm.get('segments') as FormArray;
  this.segmentService.getAll({status: "ACTIVE"}).pipe(first())
  .subscribe(
      data => {
        this.segments = data['content'];
        if ( typeof this.segments !== 'undefined'){
          this.segments.forEach((segment: any, i: number ) => {
            this.items2.push(this.getAvailableSegments());
            if(edit){
              this.premio.segments_.forEach((type: any, x: number ) => {
                if(segment.id  === type.id)
                this.segmentsList[i] = true;
              });
            }
          });
        }
        console.log('items segmentos', this.items2);
      },
      error => {
        console.log('error', error);
    });
}

}



