<app-tabela
 [title]='title'
 [Service]='service'
   [displayedColumns]='displayedColumns'
   [columnsToDisplay]='columnsToDisplay'
   [columnsBadge]='columnsBadge'
   [novo]='novo'
    [options]='options'
    [events]="eventsSubject.asObservable()"
     [view]='view'
     [edit]='edit'
     [changeStatus]='changeStatus'
     [delete]='delete'
     ></app-tabela>
