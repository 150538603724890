import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class EarningRulesService {

  constructor(private http: HttpClient) { }
  getAll(query): any {
    return this.http.get<any[]>(API_URL + `earning-rules`, {params: query});
  }
  create(data): any{
    return this.http
    .post(API_URL + 'earning-rules', data)
      .pipe(map(res => {
        return res;
    }));
  }
  get(id): any {
    return this.http.get<any[]>(API_URL + `earning-rules/` + id);
  }
  search(keyword): any {
  return this.http.get<any[]>(API_URL + `earning-rules/search/` + keyword).pipe(map(res => {
    return res;
  }));
  }
  update(data, id): any{
    return this.http
    .put(API_URL + 'earning-rules/' + id , data)
      .pipe(map(res => {
        return res;
    }));
  }

  active(id): any{
  return this.http
  .put(API_URL + 'earning-rules/' + id + '/active/',null)
    .pipe(map(res => {
      return res;
  }));
}
inactive(id): any{
  return this.http
  .put(API_URL + 'earning-rules/' + id + '/inactive/', null)
    .pipe(map(res => {
      return res;
  }));
}

  changeStatus(id, status): any{
    return this.http
    .put(API_URL + 'earning-rules/' + id + '/' + status , null)
      .pipe(map(res => {
        return res;
    }));
  }

  changeStatusDelete(id, status): any{
    return this.http
    .delete(API_URL + 'earning-rules/' + id + '/' + status )
      .pipe(map(res => {
        return res;
    }));
  }

  delete(id): any{
    return this.http
    .delete(API_URL + 'earning-rules/' + id + '/delete')
      .pipe(map(res => {
        return res;
    }));
  }
  getAllTarget(query): any {
    return this.http.get<any[]>(API_URL + `helpers/target-types`, {params: query});
  }
  createTarget(data): any{
    return this.http
    .post(API_URL + 'earning-rule-targets', data)
      .pipe(map(res => {
        return res;
    }));
  }
  getTarget(id): any {
    return this.http.get<any[]>(API_URL + `earning-rule-targets/` + id);
  }
  searchTarget(keyword): any {
  return this.http.get<any[]>(API_URL + `earning-rule-targets/search/` + keyword).pipe(map(res => {
    return res;
  }));
  }
  updateTarget(data, id): any{
    return this.http
    .put(API_URL + 'earning-rule-targets/' + id , data)
      .pipe(map(res => {
        return res;
    }));
  }

  activeTarget(id): any{
  return this.http
  .put(API_URL + 'earning-rule-targets/' + id + '/active/',null)
    .pipe(map(res => {
      return res;
  }));
}
inactiveTarget(id): any{
  return this.http
  .put(API_URL + 'earning-rule-targets/' + id + '/inactive/',null)
    .pipe(map(res => {
      return res;
  }));
}

  changeStatusTarget(id, status): any{
    return this.http
    .put(API_URL + 'earning-rule-targets/' + id + '/' + status , null)
      .pipe(map(res => {
        return res;
    }));
  }

  changeStatusDeleteTarget(id, status): any{
    return this.http
    .delete(API_URL + 'earning-rule-targets/' + id + '/' + status )
      .pipe(map(res => {
        return res;
    }));
  }

  deleteTarget(id): any{
    return this.http
    .delete(API_URL + 'earning-rule-targets/' + id + '/delete')
      .pipe(map(res => {
        return res;
    }));
  }

  getAllTypes(query): any {
    return this.http.get<any[]>(API_URL + `earning-rules-types`, {params: query});
  }
    getAllTypesEnum(): any {
    return this.http.get<any[]>(API_URL + `earning-rules-types/types`);
  }
  createType(data): any{
    return this.http
    .post(API_URL + 'earning-rules-types', data)
      .pipe(map(res => {
        return res;
    }));
  }
  getType(id): any {
    return this.http.get<any[]>(API_URL + `earning-rules-types/` + id);
  }
  searchType(keyword): any {
  return this.http.get<any[]>(API_URL + `earning-rules-types/search/` + keyword).pipe(map(res => {
    return res;
  }));
  }
  updateType(data, id): any{
    return this.http
    .put(API_URL + 'earning-rules-types/' + id , data)
      .pipe(map(res => {
        return res;
    }));
  }

  activeType(id): any{
  return this.http
  .put(API_URL + 'earning-rules-types/' + id + '/active/',null)
    .pipe(map(res => {
      return res;
  }));
}
inactiveType(id): any{
  return this.http
  .put(API_URL + 'earning-rules-types/' + id + '/inactive/', null)
    .pipe(map(res => {
      return res;
  }));
}

  changeStatusType(id, status): any{
    return this.http
    .put(API_URL + 'earning-rules-types/' + id + '/' + status , null)
      .pipe(map(res => {
        return res;
    }));
  }

  changeStatusDeleteType(id, status): any{
    return this.http
    .delete(API_URL + 'earning-rules-types/' + id + '/' + status )
      .pipe(map(res => {
        return res;
    }));
  }

  deleteType(id): any{
    return this.http
    .delete(API_URL + 'earning-rules-types/' + id + '/delete')
      .pipe(map(res => {
        return res;
    }));
  }
}
