import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnInit, AfterViewInit,ViewChild, OnDestroy, HostBinding} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { KeycloakAuthService } from 'src/app/services/keycloak.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SidenavComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild('snav') snav:any;
  expanded: boolean =  false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;

  currentUser: any;
  logado: null;

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private route: ActivatedRoute,
              private router: Router,
              private authService: KeycloakAuthService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  mobileQuery: MediaQueryList;
  menu = [
  ];

  ngOnInit(): void {
    console.log('init sidenav');
      if (!this.authService.currentUserValue) {
        this.currentUser = false;
      }
      else{
        console.log('ininstance', this.authService.currentUserValue);
        if(!this.authService.currentUserValue._instance.resourceAccess)
        this.authService.logout();
        else{
          if(this.authService.currentUserValue._instance.tokenParsed.groups[0] === "/sintaxy-admin")
          this.menu = [
              {icon: 'home', title: 'Página Inicial', path: 'homePageAdmin'},
              {icon: 'supervisor_account', title: 'Comerciantes', path: 'comerciantes'},
              {icon: 'bookmark', title: 'Níveis', path: 'levels'},
               {icon: 'settings', title: 'Parâmetros', path: '',
                children: [
                 {icon: 'minimize', title: 'Países', path: 'paises'},
                 {icon: 'minimize', title: 'Cidades', path: 'cidades'},
                {icon: 'minimize', title: 'Zonas', path: 'zonas'},
                // {icon: 'minimize', title: 'Targets de Regras', path: 'target-regras'},
                {icon: 'minimize', title: 'Tipos de Regras', path: 'tipos-regras'},
                {icon: 'minimize', title: 'Tipos de Prémios', path: 'tipos-premios'},
                {icon: 'minimize', title: 'Tipos de Segmentos', path: 'tipos-segmentos'},

               ] },
            ];
            else if(this.authService.currentUserValue._instance.tokenParsed.groups[0] === "/merchant")
            this.menu = [
              {icon: 'home', title: 'Página Inicial', path: 'homePageAdmin'},
              {icon: 'store', title: 'Lojas', path: 'lojas'},
              {icon: 'supervised_user_circle', title: 'Clientes', path: 'clientes'},
              {icon: 'assignment', title: 'Regras', path: 'regras'},
              {icon: 'folder_special', title: 'Seguimentos', path: 'seguimentos'},
              {icon: 'transfer_within_a_station', title: 'Transações', path: 'transações'},
              {icon: 'card_giftcard', title: 'Prémios', path: 'premios'},
            ];
          this.currentUser = this.authService.currentUserValue._userProfile;
        console.log('this.currentUser sidenva', this.currentUser);
        }
      }
  }
  ngAfterViewInit(): void {
  }
  toggleSidenav()
  {
    this.snav.toggle();
  }


  fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);


  // tslint:disable-next-line: variable-name
  private _mobileQueryListener: () => void;

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  // tslint:disable-next-line: typedef
logout() {
  this.authService.logout();
}
profile(){
  this.authService.profile();
}

onItemSelected(item) {
  if (!item.children || !item.children.length) {
    this.router.navigate([item.route]);
  }
  if (item.children && item.children.length) {
    this.expanded = !this.expanded;
  }
}
}
