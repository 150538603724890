<div class="container-fluid">
  <h2 class="text-siscomeTot underline">{{ page.name === 'edit' ? 'Editar' : page.name === 'view' ? 'Visualizar' :'Criar' }} Regra</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="regraForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit(page.name)">
        <div class="container-fluid paddingBottom " >
          <div class="row">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Loja</mat-label>
              <select matNativeControl  [(ngModel)]="regra.store"  id="loja" [ngClass]="{ 'is-invalid': submitted && regraForm.controls['loja'].hasError('required' || 'invalid') }"
                name="loja" formControlName="loja" >
                <option *ngFor="let store of lojas; let i = index;" [selected]="store.storeId == regra.store.storeId" label="{{store.name}} {{store.identifier}}"  [ngValue]="store"></option>
                </select>
                <div class="invalid-feedback" *ngIf="regraForm.controls['loja'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Nome</mat-label>
              <mat-hint class="text-siscomeMed">Nome da regra</mat-hint>
              <input class="form-control" [(ngModel)]="regra.name" id="name" matInput placeholder="Nome da regra" required
                name="name" formControlName="name">
                <div class="invalid-feedback" *ngIf="regraForm.controls['name'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-12">
              <mat-label>Descrição</mat-label>
              <textarea class="form-control" [(ngModel)]="regra.description" id="description" matInput placeholder="Descrição sobre a regra" required
                name="description" formControlName="description"></textarea>
              <div class="invalid-feedback" *ngIf="regraForm.controls['description'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Descrição das informações da regra</mat-hint>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Data Início</mat-label>
              <mat-hint class="text-siscomeMed">Data apartir da qual a regra terá efeito</mat-hint>
              <input type="datetime-local" class="form-control" [(ngModel)]="regra.startDate" [ngClass]="{ 'is-invalid': submitted && regraForm.controls['startDate'].hasError('required' || 'invalid') }"
               id="startDate" matInput placeholder="Data Início" min="dateRightNow"
                name="startDate" formControlName="startDate">
                <div class="invalid-feedback" *ngIf="regraForm.controls['startDate'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Data fim</mat-label>
              <mat-hint class="text-siscomeMed">Deverá ser superior a Data Início</mat-hint>
              <input type="datetime-local" class="form-control" [(ngModel)]="regra.endDate" [disabled]="!regra.startDate" [ngClass]="{ 'is-invalid': submitted && regraForm.controls['endDate'].hasError('required' || 'invalid') }"
               id="endDate" matInput placeholder="Data fim" min="regra.startDate"
                name="endDate" formControlName="endDate">
                <div class="invalid-feedback" *ngIf="regraForm.controls['endDate'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
          </div>
        </div>
        <div class="container-fluid paddingBottom" >
          <div class="row">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Tipo</mat-label>
              <select matNativeControl  [(ngModel)]="regra.earningRulesTypes" id="tipo" [ngClass]="{ 'is-invalid': submitted && regraForm.controls['tipo'].hasError('required' || 'invalid') }"
                name="tipo" formControlName="tipo" >
                <option *ngFor="let type of tipos; let i = index;" [selected]="type.id === regra.earningRulesTypes.id" label="{{type.name}}"  [ngValue]="type"></option>
                </select>
                <div class="invalid-feedback" *ngIf="regraForm.controls['tipo'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
          </div>
          <div class="row" *ngIf="regra.earningRulesTypes.typeRules === 'GENERALSPENDING'">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Valor mínimo de pedido</mat-label>
              <input type="number" class="form-control" [(ngModel)]="regra.generalSpending.minOrderValue" id="minOrderValue" matInput placeholder="Valor mínimo de pedido" required
                name="minOrderValue" formControlName="minOrderValue">
              <div class="invalid-feedback" *ngIf="regraForm.controls['minOrderValue'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Valor mínimo de pedido em $</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Pontos válidos</mat-label>
              <input type="number" class="form-control" [(ngModel)]="regra.generalSpending.pointsValue" id="pointsValue" matInput placeholder="Pontos válidos" required
                name="pointsValue" formControlName="pointsValue">
              <div class="invalid-feedback" *ngIf="regraForm.controls['pointsValue'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Pontos válidos</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-sm-5">
              <mat-label class="text-siscomeMed">SKU excluído</mat-label>
              <mat-hint class="text-siscomeMed">Produto excluído</mat-hint>
              <input class="form-control" [(ngModel)]="excludedSkus.sku" id="excludedSkus" matInput placeholder="SKU excluído"
                name="excludedSkus" formControlName="excludedSkus">
                <div class="invalid-feedback" *ngIf="regraForm.controls['excludedSkus'].hasError('required')">
                  o Campo é Obrigatório
                </div>
                <div class="row"  *ngIf="regra.generalSpending.excludedSkus.length !== 0">
                  <mat-card class="col-12">
                    <mat-card-content class="activity col-12" id="collapse1">
                      <table>
                        <tr>
                          <th class="">SKU do Produto</th>
                          <th *ngIf="page.name !== 'view'">Acções</th>
                        </tr>
                        <tr *ngFor="let produto of regra.generalSpending.excludedSkus; let i = index;">
                          <td>{{produto.sku}}</td>
                          <td *ngIf="page.name !== 'view'">
                            <a  *ngIf="page.name !== 'view'" title="Editar"  mat-icon-button aria-label="Edit" label="Edit" color="primary" (click)="editProduto('excludedSkus',i)">
                              <mat-icon aria-hidden="false">edit</mat-icon>
                            </a>
                            <a  *ngIf="page.name !== 'view'"  title="Apagar"  mat-icon-button aria-label="Delete" color="warn" (click)="deleteProduto('excludedSkus',produto, i)">
                              <mat-icon aria-hidden="false" >delete</mat-icon>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </mat-card-content>
                  </mat-card>
                </div>
            </mat-form-field>
            <div class="form-group col-sm-1">
              <button title="Adicionar SKU" type="button" mat-icon-button *ngIf="page.name !== 'view'"  class="purple right" (click)="addProduto('excludedSkus', excludedSkus)">
                <mat-icon>add</mat-icon></button>
            </div>
            <mat-form-field appearance="outline" class="form-group col-sm-5">
              <mat-label class="text-siscomeMed">SKU incluído</mat-label>
              <mat-hint class="text-siscomeMed">Produto incluído</mat-hint>
              <input class="form-control" [(ngModel)]="includedSkus.sku" id="includedSkus" matInput placeholder="SKU inluído"
                name="includedSkus" formControlName="includedSkus">
                <div class="invalid-feedback" *ngIf="regraForm.controls['includedSkus'].hasError('required')">
                  o Campo é Obrigatório
                </div>
                <div class="row"  *ngIf="regra.generalSpending.includedSkus.length !== 0">
                  <mat-card class="col-12">
                    <mat-card-content class="activity col-12" id="collapse2">
                      <table>
                        <tr>
                          <th class="">SKU do Produto</th>
                          <th *ngIf="page.name !== 'view'">Acções</th>
                        </tr>
                        <tr *ngFor="let produto of regra.generalSpending.includedSkus; let i = index;">
                          <td>{{produto.sku}}</td>
                          <td *ngIf="page.name !== 'view'">
                            <a  *ngIf="page.name !== 'view'" title="Editar"  mat-icon-button aria-label="Edit" label="Edit" color="primary" (click)="editProduto('includedSkus',i)">
                              <mat-icon aria-hidden="false">edit</mat-icon>
                            </a>
                            <a  *ngIf="page.name !== 'view'"  title="Apagar"  mat-icon-button aria-label="Delete" color="warn" (click)="deleteProduto('includedSkus',produto, i)">
                              <mat-icon aria-hidden="false" >delete</mat-icon>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </mat-card-content>
                  </mat-card>
                </div>
            </mat-form-field>
            <div class="form-group col-sm-1">
              <button title="Adicionar SKU" type="button" mat-icon-button *ngIf="page.name !== 'view'"   class="purple right" (click)="addProduto('includedSkus', includedSkus)">
                <mat-icon>add</mat-icon></button>
            </div>
          </div>
        </div>
        <div class="container-fluid paddingBottom" >
          <div class="row">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Target</mat-label>
              <select matNativeControl (change)="getTipos(regra.targetTypeEnum, false)" [(ngModel)]="regra.targetTypeEnum" [disabled]="!regra.store.storeId || !regra.earningRulesTypes.id"  id="target"
               [ngClass]="{ 'is-invalid': submitted && regraForm.controls['target'].hasError('required' || 'invalid') }"
                name="target" formControlName="target" >
                <option *ngFor="let target of targets; let i = index;" [selected]="target === regra.targetTypeEnum" label="{{target}}"  [ngValue]="target"></option>
                </select>
                <div class="invalid-feedback" *ngIf="regraForm.controls['target'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
          </div>
          <div class="container-fluid  atividadeProcedimentos" *ngIf="regra.targetTypeEnum === 'LEVEL'" >
            <div class=row>
              <section class="form-group col-12">
                <h6 class="text-siscomeMed ">Levels*</h6>
                <div formArrayName="levels"  class="form-group row">
                  <div class="col-3" *ngFor="let level of levels; let i = index;"  [formGroupName]="i">
                    <mat-checkbox   class="example-margin" [disabled]="page.name === 'view'" formControlName="level"  [(ngModel)]="levelsList[i]">
                      {{level.name}}</mat-checkbox>
                  </div>
                </div>
                <div *ngIf="submitted && regraForm.controls['levels'].hasError('required' || 'invalid')" class="text-danger">
                  <div *ngIf="regraForm.controls['levels'].hasError('required')"><small>Pelo menos um LEVEL deve ser selecionado</small></div>
                </div>
              </section>
            </div>
          </div>
          <div class="container-fluid  atividadeProcedimentos" *ngIf="regra.targetTypeEnum === 'SEGMENT'" >
            <div class=row>
              <section class="form-group col-12">
                <h6 class="text-siscomeMed ">Seguimentos do Cliente*</h6>
                <div formArrayName="segments"  class="form-group row">
                  <div class="col-3" *ngFor="let segment of segments; let i = index;"  [formGroupName]="i">
                    <mat-checkbox   class="example-margin" formControlName="segment" [disabled]="page.name === 'view'"  [(ngModel)]="segmentsList[i]">
                      {{segment.name}}</mat-checkbox>
                  </div>
                </div>
                <div *ngIf="submitted && regraForm.controls['segments'].hasError('required' || 'invalid')" class="text-danger">
                  <div *ngIf="regraForm.controls['segments'].hasError('required')"><small>Pelo menos um Seguimento deve ser selecionado</small></div>
                </div>
              </section>
            </div>
          </div>

        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="center">
      <button mat-raised-button *ngIf="page.name !== 'view'" class="siscomeTot large-button"  color="primary" (click)="onSubmit(page.name)">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ page.name === 'edit' ? 'Guardar' : 'Criar' }}</button>
        <button mat-raised-button *ngIf="page.name === 'view'"   class="siscomeTot" (click)="editar()" color="grey">Editar
        </button>
        <button mat-raised-button   class="grey" (click)="cancelar()" color="grey">{{ page.name === 'create' ? 'Cancelar' : 'Voltar' }}
        </button>
    </mat-card-actions>
  </mat-card>
</div>
