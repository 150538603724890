import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private http: HttpClient) { }
  getAll(query): any {
    return this.http.get<any[]>(API_URL + `stores`, {params: query});
  }
    getAllWeekDay(query): any {
    return this.http.get<any[]>(API_URL + `week-days`, {params: query});
  }
    getWeekDay(id): any {
    return this.http.get<any[]>(API_URL + `week-days/` + id);
  }
  create(data): any{
    return this.http
    .post(API_URL + 'stores', data)
      .pipe(map(res => {
        return res;
    }));
  }
  get(id): any {
    return this.http.get<any[]>(API_URL + `stores/` + id);
  }
  search(keyword): any {
  return this.http.get<any[]>(API_URL + `stores/search/` + keyword).pipe(map(res => {
    return res;
  }));
  }
  update(data, id): any{
    return this.http
    .put(API_URL + 'stores/' + id , data)
      .pipe(map(res => {
        return res;
    }));
  }

  active(id): any{
  return this.http
  .put(API_URL + 'stores/' + id + '/active/',null)
    .pipe(map(res => {
      return res;
  }));
}
inactive(id): any{
  return this.http
  .put(API_URL + 'stores/' + id + '/inactive/', null)
    .pipe(map(res => {
      return res;
  }));
}

  changeStatus(id, status): any{
    return this.http
    .put(API_URL + 'stores/' + id + '/' + status , null)
      .pipe(map(res => {
        return res;
    }));
  }

  changeStatusDelete(id, status): any{
    return this.http
    .delete(API_URL + 'stores/' + id + '/' + status )
      .pipe(map(res => {
        return res;
    }));
  }

  delete(id): any{
    return this.http
    .delete(API_URL + 'stores/' + id + '/delete' )
      .pipe(map(res => {
        return res;
    }));
  }
}
