import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from 'src/app/components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { EarningRulesService } from 'src/app/services/earning-rules.service';

@Component({
  selector: 'app-list-regras',
  templateUrl: './list-regras.component.html',
  styleUrls: ['./list-regras.component.css']
})
export class ListRegrasComponent implements OnInit {

  eventsSubject: Subject<any> = new Subject<any>();


  displayedColumns: string[] = ['name','store','startDate', 'endDate', 'status', 'actions'];
  imageColumn = [ {field: 'photo', header: 'Logo'}]
  columnsToDisplay = [{field: 'name', header: 'Nome', type: 'text'}, {field: 'description', header: 'Descrição', type: 'text'},
  {field: 'startDate', header: 'Data Início', type: 'date'}, {field: 'endDate', header: 'Data Fim', type: 'date'}];
  columnsObject = [{field: 'store', object:'store', subObject:'name', header: 'Loja', type: 'select', selectFieldsDados: []},];
    columnsBadge = [{field: 'status', header: 'Estado', type: 'select', selectFieldsDados:  [ {name: 'Ativo', val: 'ACTIVE'}, {name: 'Inativo', val: 'INACTIVE'}]}];
    options = {edit: true, view: true, delete: true, statusUpdate: true};
  title = 'Regras';
  novo = {link: '/regras/criar', nome: 'Nova Regra'};
  page: any = {base: null, name: null};
  service;

  constructor(private storeService: StoreService,
    private route: ActivatedRoute,
    private earningRulesService: EarningRulesService,
    private confirmationDialogService: ConfirmationDialogService,
    private toast: ToastrService,
    private router: Router,) {
this.service = this.earningRulesService.getAll; 

this.storeService.getAll(null).pipe(first())
      .subscribe(
          data => {
            console.log('lojas', data);
            this.columnsObject[0].selectFieldsDados = data['content'];
          },
          error => {
            console.log('error', error);
        });
}

ngOnInit(): void {
  this.page.base = this.route.data['value'].base;
  this.page.name = this.route.data['value'].routeName;
  }

  edit = (args: any): void => {
    console.log(args);
    this.router.navigate([this.page.base  + '/editar'], { queryParams: { id: args.id } });
    }
    view = (args: any): void => {
      console.log(args);
      this.router.navigate([this.page.base  + '/view'], { queryParams: { id: args.id } });
    }
    changeStatus = (args: any): void => {
    let textConfirm = '';
    let status = '';
    let textShow = '';

    let service;
    if (args.status === 'ACTIVE'){
      service = this.earningRulesService.inactive(args.id);
      textConfirm = 'Pretende Desactivar a Regra ' + args.name + '? Ao desactivar a Regra o estado da Regra será alterado para INATIVO.';
      status = 'INACTIVE';
      textShow = 'REGRA DESATIVADO COM SUCESSO';
    }
    else{
      service = this.earningRulesService.active(args.id);
      textConfirm = 'Pretende Activar a Regra ' + args.name + '? Ao activar a Regra o estado da Regra será alterado para ATIVO';
      status = 'ACTIVE';
      textShow = 'REGRA ACTIVADO COM SUCESSO';
    }

    this.confirmationDialogService.confirm('Por favor confirme', textConfirm)
    .then((confirmed) =>
    {
      if (confirmed){
        service.subscribe((event: any) => {
          this.toast.warning(textShow, 'Mudança de Estado');
          args.status = status;
      });
      }
    }
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

    delete = (total: any, args: any, index: number): void => {
      this.confirmationDialogService.confirm('Por favor confirme', 'Pretende apagar esta Regra ' + args.name + '? Ao eliminar os dados da Regra serão perdidos!!')
      .then((confirmed) =>
      {
        if (confirmed){
          const indexo = total.indexOf(args);
          this.earningRulesService.delete(args.id).pipe(first())
      .subscribe(
          data => {
            this.toast.warning('Eliminado com sucesso', 'Delete');
            total.splice(indexo, 1);
            console.log('total', total);
            this.eventsSubject.next(total);
          },
          error => {
              console.log('error', error);
          });
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
      console.log(args);
    }

  }
