<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Email</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let recepiente of recepientes">
              <td scope="col">{{recepiente.name || recepiente.companyName}}</td>
              <td scope="col">{{recepiente.email || recepiente.emailCompany}}</td>
            </tr>
          </tbody>
        </table>
        <mat-form-field appearance="outline" class="form-group col-12">
          <mat-label class="text-siscome">Assunto</mat-label>
          <input matInput   id="assunto" name="assunto" [ngClass]="{ 'is-invalid': submited && f.assunto.errors }" class="form-control" formControlName="assunto">
        <div *ngIf="submited && f.assunto.errors" class="invalid-feedback">
          <div *ngIf="f.assunto.errors.required">Assunto é obrigatório</div>
        </div>
        </mat-form-field>
      <div class="form-group">
        <label for="message-text" class="col-form-label text-siscomeMed">Mensagem:</label>
        <div class="editor">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
          </ngx-editor-menu>
          <ngx-editor [editor]="editor" formControlName="editorContent">
          </ngx-editor>
        </div>
        <div *ngIf="submited && f.editorContent.errors" class="text-danger">
          <div *ngIf="f.editorContent.errors.required"><small>Corpo da Mensagem é obrigatório</small> </div>
        </div>
      </div>
      <div *ngIf="files.length > 0" style="margin: 5px;" class="row">
        <mat-card style="width: 100%; margin: 2px;" class="col-12">
          <mat-card-header>
            <mat-card-title>Anexos adicionados</mat-card-title>
          </mat-card-header>
          <mat-card-content class="col-12">
            <table class="table table-bordered">
              <tr>
                <th>Nome do Ficheiro</th>
              </tr>
              <tr *ngFor="let file of files; let x = index">
                <td>{{file.name || file.fileName}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
      <div style="text-align:center; margin: 5px;" class="row">
        <button type="button" mat-button color="primary"  (click)="onClick()">
          <mat-icon>file_upload</mat-icon>
          Anexos
        </button>
        <button mat-raised-button color="warn" type="button"  *ngIf="files.length > 0" (click)="removeAll()">Remove All</button>
        <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*, .pdf, .doc, .docx" style="display:none;" />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
  </div>
