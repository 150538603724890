import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  constructor(private http: HttpClient) { }
  getAll(query): any {
    return this.http.get<any[]>(API_URL + `merchants`, {params: query});
  }
  create(data): any{
    return this.http
    .post(API_URL + 'merchants', data)
      .pipe(map(res => {
        return res;
    }));
  }
  get(email): any {
    return this.http.get<any[]>(API_URL + `merchants/` + email);
  }
  search(keyword): any {
  return this.http.get<any[]>(API_URL + `merchants/search/` + keyword).pipe(map(res => {
    return res;
  }));
  }
  update(data, email): any{
    return this.http
    .put(API_URL + 'merchants/' + email , data)
      .pipe(map(res => {
        return res;
    }));
  }

  active(email): any{
  return this.http
  .put(API_URL + 'merchants/' + email + '/active/',null)
    .pipe(map(res => {
      return res;
  }));
}
inactive(email): any{
  return this.http
  .put(API_URL + 'merchants/' + email + '/inactive/', null)
    .pipe(map(res => {
      return res;
  }));
}

  changeStatus(email, status): any{
    return this.http
    .put(API_URL + 'merchants/' + email + '/' + status , null)
      .pipe(map(res => {
        return res;
    }));
  }

  changeStatusDelete(email, status): any{
    return this.http
    .delete(API_URL + 'merchants/' + email + '/' + status )
      .pipe(map(res => {
        return res;
    }));
  }

  delete(email): any{
    return this.http
    .delete(API_URL + 'merchants/' + email )
      .pipe(map(res => {
        return res;
    }));
  }
}
