<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" style="font-size: 14px !important;"  (click)="onItemSelected(item)"
   [ngClass]="{'active': item.path ? router.isActive(item.path, true): false, 'expanded': expanded}"
   class="menu-list-item black-ico">
  <mat-icon class="black-icon routeIcon" >{{item.icon}}</mat-icon>&nbsp;
  {{item.title}}
  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-component *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-component>
</div>
