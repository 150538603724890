
import { HomeComponent } from './views/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/authGuard.service';
import { ListLojasComponent } from './views/lojas/list-lojas/list-lojas.component';
import { CreateLojasComponent } from './views/lojas/create-lojas/create-lojas.component';
import { ListCityComponent } from './views/parametros/cidades/list-city/list-city.component';
import { CreateCityComponent } from './views/parametros/cidades/create-city/create-city.component';
import { ListCountryComponent } from './views/parametros/paises/list-country/list-country.component';
import { CreateCountryComponent } from './views/parametros/paises/create-country/create-country.component';
import { ListZoneComponent } from './views/parametros/zonas/list-zone/list-zone.component';
import { CreateZoneComponent } from './views/parametros/zonas/create-zone/create-zone.component';
import { ListClienteComponent } from './views/clientes/list-cliente/list-cliente.component';
import { CreateClienteComponent } from './views/clientes/create-cliente/create-cliente.component';
import { CreateMerchantComponent } from './views/merchant/create-merchant/create-merchant.component';
import { ListMerchantComponent } from './views/merchant/list-merchant/list-merchant.component';
import { CreateRegrasComponent } from './views/regras/create-regras/create-regras.component';
import { ListRegrasComponent } from './views/regras/list-regras/list-regras.component';
import { ListTiposRegrasComponent } from './views/parametros/tiposRegras/list-tipos-regras/list-tipos-regras.component';
import { CreateTiposRegrasComponent } from './views/parametros/tiposRegras/create-tipos-regras/create-tipos-regras.component';
import { ListTargetRegrasComponent } from './views/parametros/targetRegras/list-target-regras/list-target-regras.component';
import { CreateTargetRegrasComponent } from './views/parametros/targetRegras/create-target-regras/create-target-regras.component';
import { CreateSegmentComponent } from './views/seguimentos/create-segment/create-segment.component';
import { ListSegmentComponent } from './views/seguimentos/list-segment/list-segment.component';
import { CreatePremiosComponent } from './views/premios/create-premios/create-premios.component';
import { ListPremiosComponent } from './views/premios/list-premios/list-premios.component';
import { ListRewardTypeComponent } from './views/parametros/tipoPremios/list-reward-type/list-reward-type.component';
import { CreateRewardTypeComponent } from './views/parametros/tipoPremios/create-reward-type/create-reward-type.component';
import { ListSegmentTypeComponent } from './views/parametros/tipoSegmento/list-segment-type/list-segment-type.component';
import { CreateSegmentTypeComponent } from './views/parametros/tipoSegmento/create-segment-type/create-segment-type.component';
import { CreateLevelComponent } from './views/levels/create-level/create-level.component';
import { ListLevelComponent } from './views/levels/list-level/list-level.component';
import { ListTransactionsComponent } from './views/transactions/list-transactions/list-transactions.component';


const routes: Routes = [
  {
    path: 'home', component: HomeComponent ,  data: { base: '/home', routeName: 'home'}
  },
  {
    path: '', component: HomeComponent,  data: { base: '/home', routeName: 'home'}
  },
  {
    path: 'lojas', component: ListLojasComponent, canActivate: [AuthGuardService], data: { base: '/lojas', routeName: 'lojas'}
  },
  {
    path: 'lojas/editar', component: CreateLojasComponent, canActivate: [AuthGuardService], data: { base: '/lojas', routeName: 'edit'}
  },
    {
    path: 'lojas/view', component: CreateLojasComponent, canActivate: [AuthGuardService], data: { base: '/lojas', routeName: 'view'}
  },
  {
    path: 'lojas/criar', component: CreateLojasComponent, canActivate: [AuthGuardService], data: { base: '/lojas', routeName: 'create'}
  },
  {
    path: 'paises', component: ListCountryComponent, canActivate: [AuthGuardService], data: { base: '/paises', routeName: 'paises'}
  },
  {
    path: 'paises/editar', component: CreateCountryComponent, canActivate: [AuthGuardService], data: { base: '/paises', routeName: 'edit'}
  },
    {
    path: 'paises/view', component: CreateCountryComponent, canActivate: [AuthGuardService], data: { base: '/paises', routeName: 'view'}
  },
  {
    path: 'paises/criar', component: CreateCountryComponent, canActivate: [AuthGuardService], data: { base: '/paises', routeName: 'create'}
  },
  {
    path: 'cidades', component: ListCityComponent, canActivate: [AuthGuardService], data: { base: '/cidades', routeName: 'cidades'}
  },
  {
    path: 'cidades/editar', component: CreateCityComponent, canActivate: [AuthGuardService], data: { base: '/cidades', routeName: 'edit'}
  },
    {
    path: 'cidades/view', component: CreateCityComponent, canActivate: [AuthGuardService], data: { base: '/cidades', routeName: 'view'}
  },
  {
    path: 'cidades/criar', component: CreateCityComponent, canActivate: [AuthGuardService], data: { base: '/cidades', routeName: 'create'}
  },
  {
    path: 'zonas', component: ListZoneComponent, canActivate: [AuthGuardService], data: { base: '/zonas', routeName: 'zonas'}
  },
  {
    path: 'zonas/editar', component: CreateZoneComponent, canActivate: [AuthGuardService], data: { base: '/zonas', routeName: 'edit'}
  },
    {
    path: 'zonas/view', component: CreateZoneComponent, canActivate: [AuthGuardService], data: { base: '/zonas', routeName: 'view'}
  },
  {
    path: 'zonas/criar', component: CreateZoneComponent, canActivate: [AuthGuardService], data: { base: '/zonas', routeName: 'create'}
  },
  {
    path: 'clientes', component: ListClienteComponent, canActivate: [AuthGuardService], data: { base: '/clientes', routeName: 'clientes'}
  },
  {
    path: 'clientes/editar', component: CreateClienteComponent, canActivate: [AuthGuardService], data: { base: '/clientes', routeName: 'edit'}
  },
    {
    path: 'clientes/view', component: CreateClienteComponent, canActivate: [AuthGuardService], data: { base: '/clientes', routeName: 'view'}
  },
  {
    path: 'clientes/criar', component: CreateClienteComponent, canActivate: [AuthGuardService], data: { base: '/clientes', routeName: 'create'}
  },
  {
    path: 'comerciantes', component: ListMerchantComponent, canActivate: [AuthGuardService], data: { base: '/comerciantes', routeName: 'comerciantes'}
  },
  {
    path: 'comerciantes/editar', component: CreateMerchantComponent, canActivate: [AuthGuardService], data: { base: '/comerciantes', routeName: 'edit'}
  },
    {
    path: 'comerciantes/view', component: CreateMerchantComponent, canActivate: [AuthGuardService], data: { base: '/comerciantes', routeName: 'view'}
  },
  {
    path: 'comerciantes/criar', component: CreateMerchantComponent, canActivate: [AuthGuardService], data: { base: '/comerciantes', routeName: 'create'}
  },
  {
    path: 'regras', component: ListRegrasComponent, canActivate: [AuthGuardService], data: { base: '/regras', routeName: 'regras'}
  },
  {
    path: 'regras/editar', component: CreateRegrasComponent, canActivate: [AuthGuardService], data: { base: '/regras', routeName: 'edit'}
  },
    {
    path: 'regras/view', component: CreateRegrasComponent, canActivate: [AuthGuardService], data: { base: '/regras', routeName: 'view'}
  },
  {
    path: 'regras/criar', component: CreateRegrasComponent, canActivate: [AuthGuardService], data: { base: '/regras', routeName: 'create'}
  },
  {
    path: 'tipos-regras', component: ListTiposRegrasComponent, canActivate: [AuthGuardService], data: { base: '/tipos-regras', routeName: 'tipos-regras'}
  },
  {
    path: 'tipos-regras/editar', component: CreateTiposRegrasComponent, canActivate: [AuthGuardService], data: { base: '/tipos-regras', routeName: 'edit'}
  },
    {
    path: 'tipos-regras/view', component: CreateTiposRegrasComponent, canActivate: [AuthGuardService], data: { base: '/tipos-regras', routeName: 'view'}
  },
  {
    path: 'tipos-regras/criar', component: CreateTiposRegrasComponent, canActivate: [AuthGuardService], data: { base: '/tipos-regras', routeName: 'create'}
  },
  {
    path: 'target-regras', component: ListTargetRegrasComponent, canActivate: [AuthGuardService], data: { base: '/target-regras', routeName: 'target'}
  },
  {
    path: 'target-regras/editar', component: CreateTargetRegrasComponent, canActivate: [AuthGuardService], data: { base: '/target-regras', routeName: 'edit'}
  },
    {
    path: 'target-regras/view', component: CreateTargetRegrasComponent, canActivate: [AuthGuardService], data: { base: '/target-regras', routeName: 'view'}
  },
  {
    path: 'target-regras/criar', component: CreateTargetRegrasComponent, canActivate: [AuthGuardService], data: { base: '/target-regras', routeName: 'create'}
  },
  {
    path: 'seguimentos', component: ListSegmentComponent, canActivate: [AuthGuardService], data: { base: '/seguimentos', routeName: 'seguiment'}
  },
  {
    path: 'seguimentos/editar', component: CreateSegmentComponent, canActivate: [AuthGuardService], data: { base: '/seguimentos', routeName: 'edit'}
  },
    {
    path: 'seguimentos/view', component: CreateSegmentComponent, canActivate: [AuthGuardService], data: { base: '/seguimentos', routeName: 'view'}
  },
  {
    path: 'seguimentos/criar', component: CreateSegmentComponent, canActivate: [AuthGuardService], data: { base: '/seguimentos', routeName: 'create'}
  },
  {
    path: 'premios', component: ListPremiosComponent, canActivate: [AuthGuardService], data: { base: '/premios', routeName: 'reward'}
  },
  {
    path: 'premios/editar', component: CreatePremiosComponent, canActivate: [AuthGuardService], data: { base: '/premios', routeName: 'edit'}
  },
    {
    path: 'premios/view', component: CreatePremiosComponent, canActivate: [AuthGuardService], data: { base: '/premios', routeName: 'view'}
  },
  {
    path: 'premios/criar', component: CreatePremiosComponent, canActivate: [AuthGuardService], data: { base: '/premios', routeName: 'create'}
  },
  {
    path: 'tipos-premios', component: ListRewardTypeComponent, canActivate: [AuthGuardService], data: { base: '/tipos-premios', routeName: 'tipos-premios'}
  },
  {
    path: 'tipos-premios/editar', component: CreateRewardTypeComponent, canActivate: [AuthGuardService], data: { base: '/tipos-premios', routeName: 'edit'}
  },
    {
    path: 'tipos-premios/view', component: CreateRewardTypeComponent, canActivate: [AuthGuardService], data: { base: '/tipos-premios', routeName: 'view'}
  },
  {
    path: 'tipos-premios/criar', component: CreateRewardTypeComponent, canActivate: [AuthGuardService], data: { base: '/tipos-premios', routeName: 'create'}
  },
  {
    path: 'tipos-segmentos', component: ListSegmentTypeComponent, canActivate: [AuthGuardService], data: { base: '/tipos-segmentos', routeName: 'tipos-segmentos'}
  },
  {
    path: 'tipos-segmentos/editar', component: CreateSegmentTypeComponent, canActivate: [AuthGuardService], data: { base: '/tipos-segmentos', routeName: 'edit'}
  },
    {
    path: 'tipos-segmentos/view', component: CreateSegmentTypeComponent, canActivate: [AuthGuardService], data: { base: '/tipos-segmentos', routeName: 'view'}
  },
  {
    path: 'tipos-segmentos/criar', component: CreateSegmentTypeComponent, canActivate: [AuthGuardService], data: { base: '/tipos-segmentos', routeName: 'create'}
  },
  {
    path: 'levels', component: ListLevelComponent, canActivate: [AuthGuardService], data: { base: '/levels', routeName: 'levels'}
  },
  {
    path: 'levels/editar', component: CreateLevelComponent, canActivate: [AuthGuardService], data: { base: '/levels', routeName: 'edit'}
  },
    {
    path: 'levels/view', component: CreateLevelComponent, canActivate: [AuthGuardService], data: { base: '/levels', routeName: 'view'}
  },
  {
    path: 'levels/criar', component: CreateLevelComponent, canActivate: [AuthGuardService], data: { base: '/levels', routeName: 'create'}
  },
  {
    path: 'transações', component: ListTransactionsComponent, canActivate: [AuthGuardService], data: { base: '/transações', routeName: 'transações'}
  },
  { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
