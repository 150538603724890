import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MerchantService } from 'src/app/services/merchant.service';

@Component({
  selector: 'app-create-merchant',
  templateUrl: './create-merchant.component.html',
  styleUrls: ['./create-merchant.component.css']
})
export class CreateMerchantComponent implements OnInit {

  comerciante: any = {};
  items;
  zones: any = [];
  comercianteForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl = '/comerciantes';
    error = '';
    page: any = {base: null, name: null};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastrService,
    private merchantService: MerchantService) { }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.merchantService.get(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.comerciante = data;
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.comercianteForm = this.fb.group({
        name: new FormControl(null, Validators.required),
        apelido: new FormControl(null, Validators.required),
        phone: new FormControl(null, Validators.required),
        email: new FormControl(null, Validators.required),
        nif: new FormControl(null, Validators.required),
    });
    if (this.page.name === 'view')
    this.comercianteForm.disable();

    }

    get f(): any { return this.comercianteForm.controls; }

      onSubmit(page): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.comercianteForm.invalid) {
          console.log('invaid', this.comercianteForm);
          return;
        }
        console.log('valid', this.comerciante);
        this.loading = true;

        if (page === 'edit'){
          this.merchantService.update(this.comerciante, this.comerciante.email)
            .subscribe(
                data => {
                  this.toast.success('Editaste seus dados com sucesso', 'Edição', { positionClass: 'toast-top-center' });
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });

        }
        else if (page === 'create'){
          this.merchantService.create(this.comerciante)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Refgistastes seus dados com sucesso', 'Criação', { positionClass: 'toast-top-center' });
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  this.loading = false;
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.comercianteForm.enable();
    }
    maxLenght(event, field, max): void{
      console.log(event, field, max);
      if (field && field.toString().length === max){
        event.preventDefault();
      }
    }
    checkPhone(event, field): void{
      if(field){
        var valor = field.toString();
        console.log(valor);
        console.log(valor.length);
        if(valor.length === 3 || valor.length === 7)
        this.comerciante.phone = valor + '-';
        console.log(valor + '-');
      }
    }

}
