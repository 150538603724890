<div class="container-fluid">
  <h2 class="text-siscomeTot underline">{{ page.name === 'edit' ? 'Editar' : page.name === 'view' ? 'Visualizar' :'Criar' }} Tipo de Regra</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="regraForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit(page.name)">
        <div class="row">
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Nome</mat-label>
            <mat-hint class="text-siscomeMed">Nome do Tipo de regra</mat-hint>
            <input class="form-control" [(ngModel)]="regra.name" id="name"
              [ngClass]="{ 'is-invalid': submitted && regraForm.controls['name'].hasError('required' || 'invalid') }" matInput placeholder="Nome do Tipo de regra" required
              name="name" formControlName="name">
              <div class="invalid-feedback" *ngIf="regraForm.controls['name'].hasError('required')">
                o Campo é Obrigatório
              </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Identificador do tipo de regra</mat-label>
            <select matNativeControl  [(ngModel)]="regra.typeRules"  id="typeRules" [disabled]="page.name !== 'create'" [ngClass]="{ 'is-invalid': submitted && regraForm.controls['typeRules'].hasError('required' || 'invalid') }"
              name="typeRules" formControlName="typeRules" required>
              <option *ngFor="let tipo of tipos; let i = index;" [selected]="tipo === regra.typeRules" label="{{tipo}}"  [value]="tipo"></option>
              </select>
              <div class="invalid-feedback" *ngIf="regraForm.controls['typeRules'].hasError('required')">
                o Campo é Obrigatório
              </div>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="outline" class="form-group col-12">
            <mat-label>Descrição</mat-label>
            <textarea class="form-control" [(ngModel)]="regra.description" id="description" [ngClass]="{ 'is-invalid': submitted && regraForm.controls['description'].hasError('required' || 'invalid') }"
             matInput placeholder="Descrição sobre a regra" required
              name="description" formControlName="description"></textarea>
            <div class="invalid-feedback" *ngIf="regraForm.controls['description'].hasError('required')">
              o Campo é Obrigatório
            </div>
            <mat-hint>Descrição das informações do Tipo de regra</mat-hint>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="center">
      <button mat-raised-button *ngIf="page.name !== 'view'" class="siscomeTot large-button"  color="primary" (click)="onSubmit(page.name)">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ page.name === 'edit' ? 'Guardar' : 'Criar' }}</button>
        <button mat-raised-button *ngIf="page.name === 'view'"   class="siscomeTot" (click)="editar()" color="grey">Editar
        </button>
        <button mat-raised-button   class="grey" (click)="cancelar()" color="grey">{{ page.name === 'create' ? 'Cancelar' : 'Voltar' }}
        </button>
    </mat-card-actions>
  </mat-card>
</div>
