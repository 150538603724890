import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SegmentService } from 'src/app/services/segment.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-create-segment',
  templateUrl: './create-segment.component.html',
  styleUrls: ['./create-segment.component.css']
})
export class CreateSegmentComponent implements OnInit {
  seguimento: any = {
    segmentType:{},
    aniversary: {},
    store: {}
  };
  tipos;
  lojas;

  seguimentoForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl = '/seguimentos';
  error = '';
  page: any = {base: null, name: null};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastrService,
    private storeService: StoreService,
    private segmentService: SegmentService,)  {
      this.storeService.getAll({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            console.log('lojas', data);
            this.lojas = data['content'];
          },
          error => {
            console.log('error', error);
        });
        this.segmentService.getAllTypes({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            console.log('tipos', data);
            this.tipos = data['content'];
          },
          error => {
            console.log('error', error);
        });
    }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.segmentService.get(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.seguimento = data;
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.seguimentoForm = this.fb.group({
        loja: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        tipo: new FormControl('', Validators.required),
        days: new FormControl('', Validators.required),
    });
    if (this.page.name === 'view')
    this.seguimentoForm.disable();

    }

      onSubmit(page): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.seguimentoForm.invalid) {
          console.log('invaid', this.seguimentoForm);
          return;
        }
        console.log('valid', this.seguimento);
        this.loading = true;

        if (page === 'edit'){
          this.segmentService.update(this.seguimento, this.seguimento.id)
            .subscribe(
                data => {
                  this.toast.success('Editaste um  seguimento com sucesso', 'Edição', { positionClass: 'toast-top-center' });
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });

        }
        else if (page === 'create'){
          this.segmentService.create(this.seguimento)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Criaste um  seguimento com sucesso', 'Criação', { positionClass: 'toast-top-center' });
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  this.loading = false;
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.seguimentoForm.enable();
    }

}


