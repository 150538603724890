import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import { CountryService } from 'src/app/services/country.service';
import { CityService } from 'src/app/services/city.service';
import { ZoneService } from 'src/app/services/zone.service';
import { KeycloakAuthService } from 'src/app/services/keycloak.service';
import { ConfirmationDialogService } from 'src/app/components/confirmation-dialog/confirmation-dialog.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AddEmployService } from 'src/app/components/add-employ/add-employ.service';
import { LevelService } from 'src/app/services/level.service';

@Component({
  selector: 'app-create-lojas',
  templateUrl: './create-lojas.component.html',
  styleUrls: ['./create-lojas.component.css']
})
export class CreateLojasComponent implements OnInit {
  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef; files  = [];
  @ViewChild('fileUpload', {static: false}) photoUpload: ElementRef;
  @ViewChild('coverUpload', {static: false}) coverUpload: ElementRef;

  loja: any = {
    zone : null,
    cachierList: [],
    storeOpeningHoursList: [],
    storeGalleryList: [],
    storeLevelConfigList: [],
  };
  storeOpeningHoursList = [];
  levelConfigList = [];
  storeOpeningHour: any = {
    weekDay: {},
    endHour: null,
    startHour: null,
  };
  weekDays;

  cashier: any =  {
    assignedEmployeeList: [],
  };
  employee :  any= {
  }
  numberClick = 0;
  items;
  items2;
  paises;
  levels;
  country: any= null;
  profileCoverPhoto;
  photo;
  profileCoverPhotoAntigo;
  photoAntigo;
  cidades: any = [];
  city: any= null;
  zones: any = [];
  lojaForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl = '/lojas';
    error = '';
    page: any = {base: null, name: null};
    tabIndex = 0;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastrService,
    private levelService: LevelService,
    private countryService: CountryService,
    protected authService: KeycloakAuthService,
    private confirmationDialogService: ConfirmationDialogService,
    private cityService: CityService,
    private zoneService : ZoneService,
    private uploadService: FileUploadService,
    private addEmployService: AddEmployService,
    private storeService: StoreService) {
      this.countryService.getAll({status: "ACTIVE"}).pipe(first())
                .subscribe(
                    data => {
                      console.log('data', data);
                      this.paises = data['content'];
                    },
                    error => {
                      console.log('error', error);
                  });
                  this.levelService.getAll({status: "ACTIVE"}).pipe(first())
                .subscribe(
                    data => {
                      console.log('data', data);
                      this.levels = data['content'];
                      this.getLevels('items','levelsConfig');
                    },
                    error => {
                      console.log('error', error);
                  });
                  this.storeService.getAllWeekDay(null).pipe(first())
                .subscribe(
                    data => {
                      console.log('weekDays', data);
                      this.weekDays = data['content'];
                      this.getDays('items','weekDays');
                    },
                    error => {
                      console.log('error', error);
                  });
    }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.storeService.get(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.loja = data;
                if(this.loja.storeOpeningHoursList.length !== 0)
                 this.storeOpeningHoursList = this.loja.storeOpeningHoursList ;
                 if(this.loja.storeLevelConfigList.length !== 0)
                 this.levelConfigList = this.loja.storeLevelConfigList ;
                this.loja.storeGalleryList.forEach(file => {
                   this.uploadService.viewGaleryFile(this.loja.storeId, file.id).subscribe((baseImage: any) => {
                     console.log('baseImage', baseImage)
                       const reader = new FileReader();
                    reader.readAsDataURL(baseImage);
                    reader.onload = (_event) => {
                      file.imgURL = reader.result;
                   };
                     this.files.push(file);
                   });
               });

                if(this.loja.photo)
                this.uploadService.viewFile(this.loja.storeId, 'DEFAULT').subscribe((file: any) => {
                   const reader = new FileReader();
                   reader.readAsDataURL(file);
                   reader.onload = (_event) => {
                   file.imgURL = reader.result;
                  };
                   this.photo = file;
                   this.photoAntigo = file;

                 });
                if(this.loja.profileCoverPhoto)
                this.uploadService.viewFile(this.loja.storeId, 'PROFILECOVER').subscribe((file: any) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (_event) => {
                  file.imgURL = reader.result;
                 };
                  this.profileCoverPhoto = file;
                  this.profileCoverPhotoAntigo =file;

                });

                this.country = this.loja.zone.city.country;
                this.city = this.loja.zone.city;
                 this.getCities(this.country, false);
                 this.getZones(this.city, false);
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.lojaForm = this.fb.group({
        name: new FormControl('', Validators.required),
        identifier: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        country: new FormControl(null, Validators.required),
        city:new FormControl(null, Validators.required),
        zone: new FormControl(null, Validators.required),
        photo: new FormControl(null),
        cover: new FormControl(null),
        workstationIdentifier: new FormControl(null),
        pin: new FormControl(null),
        periodo: new FormControl(null),
        criterio: new FormControl(null, Validators.required),
        weekDays: this.fb.array([ ]),
        levelsConfig: this.fb.array([ ]),
    });
    if (this.page.name === 'view')
    this.lojaForm.disable();

    }
    getAvailableDays(): FormGroup {
      return this.fb.group({
        weekDay: null,
        endHour: null,
        startHour: null,
        secondStartHour: null,
        secondEndHour: null,
      });
    }
    getAvailableLevels(): FormGroup {
      return this.fb.group({
        maxPonits: null,
        minPoints: null,
        maxOderValue: null,
        minOrderValue: null,
      });
    }


    getDays(items, weekDays, edit=false): any{
      this[items] = this.lojaForm.get(weekDays) as FormArray;
                if ( typeof this.weekDays !== 'undefined'){
                  this.weekDays.forEach((day: any, i: number ) => {
                    this[items].push(this.getAvailableDays());
                    if(!edit)
                    this.storeOpeningHoursList[i] = {
                      weekDay:day
                    };
                  });
                }
    }
    getLevels(items, levels, edit=false): any{
      this[items] = this.lojaForm.get(levels) as FormArray;
                if ( typeof this.levels !== 'undefined'){
                  this.levels.forEach((level: any, i: number ) => {
                    this[items].push(this.getAvailableLevels());
                    if(!edit)
                    this.levelConfigList[i] = {
                      level:level
                    };
                  });
                }
                console.log('form', this.lojaForm);
    }

      onSubmit(page): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.lojaForm.invalid) {
          console.log('invaid', this.lojaForm);
          if(this.lojaForm.controls.name.errors
              || this.lojaForm.controls.identifier.errors
                || this.lojaForm.controls.country.errors
                  || this.lojaForm.controls.city.errors
                    || this.lojaForm.controls.zone.errors
                    || this.lojaForm.controls.description.errors){
                      this.selectTab(0);

          }
          else if(this.lojaForm.controls.criterio.errors || this.lojaForm.controls.levelsConfig.status === 'INVALID'){
            this.selectTab(1)
          }
          return;
        }
        this.loja.storeOpeningHoursList = this.storeOpeningHoursList;
        this.loja.storeLevelConfigList = this.levelConfigList;
        console.log('valid', this.loja);
        this.loading = true;

        if (page === 'edit'){
          this.storeService.update(this.loja, this.loja.storeId)
            .subscribe(
                data => {
                  this.toast.success('Editaste uma loja com sucesso', 'Edição', { positionClass: 'toast-top-center' });

                  console.log('this.photo', this.photo);
                  if(this.photo && this.photo !== this.photoAntigo)
                  this.uploadFile(this.photo, data.storeId,'DEFAULT');
                  console.log('this.profileCoverPhoto', this.profileCoverPhoto);
                  if(this.profileCoverPhoto && this.profileCoverPhoto !== this.profileCoverPhotoAntigo)
                  this.uploadFile(this.profileCoverPhoto, data.storeId,'PROFILECOVER');
                  this.uploadFiles(data.storeId, this.files);
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });

        }
        else if (page === 'create'){
          this.storeService.create(this.loja)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Criaste uma loja com sucesso', 'Criação', { positionClass: 'toast-top-center' });

                console.log('this.photo', this.photo);
                if(this.photo)
                this.uploadFile(this.photo, data.storeId,'DEFAULT');
                console.log('this.profileCoverPhoto', this.profileCoverPhoto);
                if(this.profileCoverPhoto)
                this.uploadFile(this.profileCoverPhoto, data.storeId,'PROFILECOVER');
                this.uploadFiles(data.id, this.files);
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  this.loading = false;
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.lojaForm.enable();
    }

    onClick(campoUpload, campoFicheiro): void  {
      this[campoUpload].nativeElement.value = '';
      let fileUpload = this[campoUpload].nativeElement; fileUpload.onchange = () => {
        console.log('change');
      for (let index = 0; index < fileUpload.files.length; index++)
      {
       const file = fileUpload.files[index];
       const reader = new FileReader();
       reader.readAsDataURL(file);
       reader.onload = (_event) => {
          file.imgURL = reader.result;
        }
       console.log('file', file);
       if (campoFicheiro === 'files'){
        this.files.push(file);
       }
       else{
         this[campoFicheiro] = file;
       }
      }
      };
      if( this.numberClick === 0 ){
        console.log('numberClick inside', this.numberClick);
        fileUpload.click();
        this.numberClick = 1;
        setTimeout(() => {
          this.numberClick = 0;
          }, 3000);
      }
    }
    removeAll(): void{
      this.files = [];
    }
    removeFile(campo, file, type): void{
      console.log('deleted file', file)
      if (!file.name){
        this.confirmationDialogService.confirm('Por favor confirme', 'Queres mesmo apagar a foto ?')
        .then((confirmed) =>
        {
          if (confirmed){
            this[campo] = null;
             this.uploadService.deleteStoreFile(this.loja.storeId, type).subscribe((event: any) => {
                 this.toast.warning(file.filename + ' Eliminado com sucesso', 'Delete');
                 this[campo] = null;
                 this.loja[campo] = null;
             });
          }
        }
        )
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
      }
      else{
        this[campo] = null;
      }

    }
    removeFileFromList(index,file): void{
      if (file.id){
      this.confirmationDialogService.confirm('Por favor confirme', 'Queres mesmo apagar o ficheiro ' + file.fileName + '?')
      .then((confirmed) =>
      {
        if (confirmed){
          this.uploadService.deleteStoreGalleryFile(this.loja.storeId, this.loja.storeGalleryList[index].id).subscribe((event: any) => {
              this.toast.warning(file.filename + ' Eliminado com sucesso', 'Delete');
              this.files.splice(index, 1);
              this.loja.storeGalleryList.splice(index, 1);
          });
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
    else{
      this.files.splice(index, 1);
    }
    }

    getCities(pais, edit): void{
      console.log('pais', pais)
      if(pais){
        this.cidades = pais.cityList;
      }
      if(edit){
        this.city = null;
        this.loja.zone = null;
        this.zones = [];
      }
    }
    getZones(cidade, edit): void{
      console.log('cidade', cidade)
      if(cidade){
        this.zones = cidade.zoneList;
      }
      else{
        this.zones = [];
      }
      if(edit){
        this.loja.zone = null;
      }
    }

    private uploadFiles(id, lista): void {
      this.fileUpload.nativeElement.value = '';
      lista.forEach(file => {
          this.uploadFile(file, id, 'GALLERY')
      });
    }
    uploadFile(file, id, type): void {

      const formData = new FormData();
        if(!file.id){
          formData.append('file', file);
          console.log('uplod file', file, id);
          this.uploadService.uploadFile(formData, id, type ).subscribe((event: any) => {
            if (typeof (event) === 'object') {
              console.log(event.body);
              this.toast.success('Imagen' +file.name+'  com sucesso', 'Upload');
            }
          });
        }
    }

    selectTab(tabIndex): void {
      this.tabIndex = tabIndex;
    }
    toggleCashier(index): void{
      $('#collapse'+index).toggle();
    }
    addCashier(cashier): void{
      if(!cashier.workstationIdentifier)
      {
        alert('Prencha o campo Indetificador');
        $("#workstationIdentifier").focus();
        return;
      }
      if(!cashier.pin)
      {
        alert('Prencha o campo Pin');
        $("#pin").focus();
        return;
      }
      if(cashier.index !== undefined)
      this.loja.cachierList[cashier.index] = cashier;
      else
      this.loja.cachierList.push(cashier)

      this.cashier = {
        assignedEmployeeList: []
      };
    }
    editCashier(i):void {
      this.cashier =  Object.assign({}, this.loja.cachierList[i]);
      this.cashier.index = i;
    }
    deleteCashier(cashier, i){
      if(cashier.id)
      this.confirmationDialogService.confirm('Por favor confirme', 'Pretende apagar esta Caixa ' + cashier.workstationIdentifier + '? Ao eliminar os dados da Caixa serão perdidos!!')
      .then((confirmed) =>
      {
        if (confirmed){
          this.loja.cachierList.splice(i, 1);
        }
      }
      ).catch((err) => console.log('User dismissed the dialog ', err));
      else
      this.loja.cachierList.splice(i, 1);

    }

    addEmploy(i): void{
          this.addEmployService.add('Adiocionar um Empregado',  this.employee)
            .then((employ) =>
            {
              this.loja.cachierList[i].assignedEmployeeList.push(employ)
            }
            )
            .catch((err) => {
              console.log('User dismissed the dialog ', err);
            });
    }

    editEmploy(item, x, i): void{
      item.index = i;
      this.addEmployService.add('Editar um Empregado',  item)
              .then((employ) =>
              {
                if (typeof  employ.index !== 'undefined'){
                  this.loja.cachierList[x].assignedEmployeeList[employ.index] = Object.assign({}, employ);
                }
                else {
                  const x = Object.assign({}, employ);
                  this.loja.cachierList[x].assignedEmployeeList.push(employ)
                }
              }
              )
              .catch((err) => {
                console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
              });
      this.employee = this.loja.cachierList[x].assignedEmployeeList[i];
    }
    deleteEmploy(employ,x, i){
      if(employ.id)
      this.confirmationDialogService.confirm('Por favor confirme', 'Pretende apagar este Empregado ' + employ.name + '? Ao eliminar os dados do Empregado serão perdidos!!')
      .then((confirmed) =>
      {
        if (confirmed){
          this.loja.cachierList[x].assignedEmployeeList.splice(i, 1);
        }
      }
      ).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
      else
      this.loja.cachierList[x].assignedEmployeeList.splice(i, 1);

    }

    equals(o1: any, o2: any) {
      if(o1 && o2)
      return o1.id === o2.id;
      else return false
   }

}
