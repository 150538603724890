
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="white" class="example-toolbar"  *ngIf="currentUser">

    <div class="col-sm-8">
      <button mat-icon-button (click)="toggleSidenav()" class="stripe-menu">
        <mat-icon>menu</mat-icon>
      </button>
      <h1 style="margin-left: 2px;display: inline-block;">Lealty</h1>
    </div>


    <div class="text-right col-sm-4">
      <button mat-button id="profileMenu" [matMenuTriggerFor]="profileMenu">
        <mat-icon aria-hidden="false" color="black" class="black-icon" aria-label="Example home icon">account_circle</mat-icon>
        Minha Conta
      </button>
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item (click)="profile()" >Perfil {{currentUser.firstName}} {{currentUser.lastName}}</button>
        <button mat-menu-item (click)="logout()">Sair</button>
      </mat-menu>
    </div>


  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav *ngIf="currentUser"  class="navMenu" #snav id="snav" name="snav" [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" [opened]="true"
      fixedTopGap="80">
      <mat-nav-list class="menuList" >
          <app-menu-list-component *ngFor="let item of menu" [item]="item"></app-menu-list-component>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>


  </mat-sidenav-container>
</div>
