import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { KeycloakAuthService } from './keycloak.service';
import {ErrorDialogComponent} from '../components/error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private authenticationService: KeycloakAuthService,
              public dialog: MatDialog,
              public toasterService: ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
              if (evt.body && evt.body.success) {
                  this.toasterService.success(evt.body.success.message, evt.body.success.title, { positionClass: 'toast-bottom-center' });
              }
          }
      }),
      catchError(err => {
        console.log('erro a interceptor', err);
        if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                  // auto logout if 401 response returned from api
                  this.authenticationService.logout();
              }
              if (err.status === 403) {
                this.authenticationService.logout();
              }
              if (err.status === 404) {
                this.dialog.open(ErrorDialogComponent, {
                  width: '50%',
                  data: {error: err, name: 'Not Found'}
                });
            }
              if (err.status === 400) {
                console.log('errrrrrr', err)
                err.error.errors = null;
                err.error.message = 'Não tens permissão para realizar esta tarefa!! Por Favor contactar o admistrador';
                this.dialog.open(ErrorDialogComponent, {
                width: '50%',
                data: {error: err, name: 'Sem Permissão!!'}
              });
            }
              if (err.status === 409) {
              this.dialog.open(ErrorDialogComponent, {
                width: '50%',
                data: {error: err, name: 'Validações do Formulário'}
              });
          }
          if (err.status === 405) {
            this.dialog.open(ErrorDialogComponent, {
              width: '50%',
              data: {error: err, name: 'Validações do Formulário'}
            });
        }
        if (err.status === 422) {
          console.log('errrrrrr', err)
          this.dialog.open(ErrorDialogComponent, {
            width: '50%',
            data: {error: err, name: err.error.message}
          });
      }
              if (err.status === 500) {
                err.error.errors = null;
                err.error.message = 'Lamentamos, erro no servidor!! Por Favor, Tente mais tarde';
                this.dialog.open(ErrorDialogComponent, {
                width: '50%',
                data: {error: err, name: 'Erro No servidor!!'}
              });
          }
        }
        const error = err.error ? err.error.message : err.statusText;
        return throwError(error);
      }));
  }
}
