// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos
const keycloakConfig: KeycloakConfig = {
  url: 'https://auth.test.sintaxy.com/auth',
  realm: 'lealty',
  clientId: 'lealty-frontend',
};
export const environment = {
  production: true,
  apiUrl: 'https://lealty.sintaxy.com/api/v1/',
  keycloak: keycloakConfig,
  landimPage: 'https://lealty.sintaxy.com',
  homePage: 'https://lealty.sintaxy.com/home'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
