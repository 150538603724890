import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import { CountryService } from 'src/app/services/country.service';
import { CityService } from 'src/app/services/city.service';
import { ZoneService } from 'src/app/services/zone.service';
import { KeycloakAuthService } from 'src/app/services/keycloak.service';
import { ConfirmationDialogService } from 'src/app/components/confirmation-dialog/confirmation-dialog.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { EarningRulesService } from 'src/app/services/earning-rules.service';
import { SegmentService } from 'src/app/services/segment.service';
import { LevelService } from 'src/app/services/level.service';

@Component({
  selector: 'app-create-regras',
  templateUrl: './create-regras.component.html',
  styleUrls: ['./create-regras.component.css']
})
export class CreateRegrasComponent implements OnInit {

  regra: any = {
    earningRulesTypes: {
    },
    store: {},
    levels: [],
    segments: [],
    generalSpending: {
      excludedSkus: [],
      includedSkus: []

    }
  };
  items;
  items2;
  excludedSkus: any ={};
  includedSkus: any ={};
  lojas;
  tipos;
  levels;
  levelsList = [];
  segmentsList = [];
  segments;
  targets;
  regraForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl = '/regras';
  error = '';
  page: any = {base: null, name: null};
  dateRightNow = Date.now();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastrService,
    private countryService: CountryService,
    protected authService: KeycloakAuthService,
    private confirmationDialogService: ConfirmationDialogService,
    private earningRulesService: EarningRulesService,
    private cityService: CityService,
    private zoneService : ZoneService,
    private uploadService: FileUploadService,
    private segmentService: SegmentService,
    private levelService: LevelService,
    private storeService: StoreService)  {
      this.storeService.getAll({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            console.log('lojas', data);
            this.lojas = data['content'];
          },
          error => {
            console.log('error', error);
        });
        this.earningRulesService.getAllTypes({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            console.log('tipos', data);
            this.tipos = data['content'];
          },
          error => {
            console.log('error', error);
        });
        this.earningRulesService.getAllTarget({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            console.log('targets', data);
            this.targets = data;
          },
          error => {
            console.log('error', error);
        });
    }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);
      console.log('dateRightNow', this.dateRightNow)

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.earningRulesService.get(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.regra = data;
                this.getTipos(this.regra.targetTypeEnum, true);

                this.regra.endDate = this.setValue( this.regra.endDate);
                this.regra.startDate = this.setValue(this.regra.startDate);
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.regraForm = this.fb.group({
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        loja: new FormControl('', Validators.required),
        tipo: new FormControl('', Validators.required),
        minOrderValue: new FormControl('', Validators.required),
        pointsValue: new FormControl('', Validators.required),
        target: new FormControl('', Validators.required),
        excludedSkus: new FormControl(null),
        includedSkus: new FormControl(null),
        endDate: new FormControl('', Validators.required),
        startDate: new FormControl('', Validators.required),
        levels: this.fb.array([ ]),
        segments: this.fb.array([ ])
    });
    if (this.page.name === 'view')
    this.regraForm.disable();

    }

      onSubmit(page): void {
        this.submitted = true;

        console.log('type', this.regra.targetTypeEnum)

        if(this.regra.targetTypeEnum === 'LEVEL'){
          this.regraForm.controls['segments'].setErrors(null);
          this.regra.levels = [];
        this.levelsList.forEach( (item: any, i: number  )  => {
          console.log('item',item)
          if (item === true) {
            this.regra.levels.push(this.levels[i]);
          }
        });
          if(this.regra.levels.length === 0){
            this.regraForm.controls['levels'].setErrors({'required': true});
          }
          else{
            this.regraForm.controls['levels'].setErrors(null);
          }
        }
        else if (this.regra.targetTypeEnum === 'SEGMENT'){
          this.regraForm.controls['levels'].setErrors(null);
          this.regra.segments = [];
          this.segmentsList.forEach( (item: any, i: number  )  => {
            if (item === true) {
              this.regra.segments.push(this.segments[i]);
            }
          });
          if(this.regra.segments.length === 0){
            this.regraForm.controls['segments'].setErrors({'required': true});
          }
          else{
            this.regraForm.controls['segments'].setErrors(null);
          }
        }
        console.log('segment form', this.regraForm.controls['segments']);

        // stop here if form is invalid
        if (this.regraForm.invalid) {
          console.log('invaid', this.regraForm);
          return;
        }

        this.regra.endDate = this.getValue( this.regra.endDate);
        this.regra.startDate = this.getValue(this.regra.startDate);
        this.loading = true;

        if (page === 'edit'){
          this.earningRulesService.update(this.regra, this.regra.id)
            .subscribe(
                data => {
                  this.toast.success('Editaste uma Regra com sucesso', 'Edição', { positionClass: 'toast-top-center' });
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                    if(this.regra.endDate)
                    this.regra.endDate = this.setValue( this.regra.endDate);
                    if(this.regra.startDate)
                    this.regra.startDate = this.setValue(this.regra.startDate);
                });

        }
        else if (page === 'create'){
          this.earningRulesService.create(this.regra)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Criaste uma Regra com sucesso', 'Criação', { positionClass: 'toast-top-center' });
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  this.loading = false;
                  if(this.regra.endDate)
                  this.regra.endDate = this.setValue( this.regra.endDate);
                  if(this.regra.startDate)
                  this.regra.startDate = this.setValue(this.regra.startDate);
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.regraForm.enable();
    }

    setValue(date): any {
      var isoString = date.toString()
      isoString = isoString.replace(" ", "T");
      return isoString
  }
  getValue(date): any {
    var isoString = date.toString()
    if(isoString.includes("T")){
      isoString = isoString.replace("T", " ");
    }
    console.log('length', isoString.length)
    if(isoString.length === 16){
      isoString = isoString + ':00';
    }
    return isoString
}
getAvailableLevels(): FormGroup {
  return this.fb.group({
    level: false,
  });
}

getLevels(edit): any{
  this.items = this.regraForm.get('levels') as FormArray;
  this.levelService.getAll({status: "ACTIVE"}).pipe(first())
      .subscribe(
          data => {
            this.levels = data['content'];
            if ( typeof this.levels !== 'undefined'){
              this.levels.forEach((level: any, i: number ) => {
                this.items.push(this.getAvailableLevels());
                if(edit){
                  this.regra.levels.forEach((type: any, x: number ) => {
                    if(level.id  === type.id)
                    this.levelsList[i] = true;
                  });
                }
              });
            }
            console.log('items ants', this.items);
          },
          error => {
            console.log('error', error);
        });
}

getAvailableSegments(): FormGroup {
  return this.fb.group({
    segment: false,
  });
}
getTipos(type, edit): any {
  if(type === 'LEVEL')
  this.getLevels(edit);
  else if(type === 'SEGMENT')
  this.getSegments(edit);
}

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

getSegments(edit): any{
  this.items2 = this.regraForm.get('segments') as FormArray;
  this.segmentService.getAll({status: "ACTIVE"}).pipe(first())
  .subscribe(
      data => {
        this.segments = data['content'];
        if ( typeof this.segments !== 'undefined'){
          this.segments.forEach((segment: any, i: number ) => {
            this.items2.push(this.getAvailableSegments());
            if(edit){
              this.regra.segments.forEach((type: any, x: number ) => {
                if(segment.id  === type.id)
                this.segmentsList[i] = true;
              });
            }
          });
        }
        console.log('items segmentos', this.items2);
      },
      error => {
        console.log('error', error);
    });
}


addProduto(type, produto): void{
  if(!produto.sku)
  {
    alert('Prencha o campo SKU do produto');
    if(type === 'excludedSkus')
    $("#excludedSkus").focus();
    else if(type === 'includedSkus')
    $("#includedSkus").focus();
    return;
  }
  if(produto.index !== undefined)
  this.regra.generalSpending[type][produto.index] = produto;
  else
  this.regra.generalSpending[type].push(produto)

  this[type] = {};
}
editProduto(type,i):void {
  this[type] =  Object.assign({}, this.regra.generalSpending[type][i]);
  this[type].index = i;
}
deleteProduto(type, produto, i){
  if(produto.id)
  this.confirmationDialogService.confirm('Por favor confirme', 'Pretende apagar este Produto ' + produto.sku + '? Ao eliminar os dados do Produto serão perdidos!!')
  .then((confirmed) =>
  {
    if (confirmed){
      this.regra.generalSpending[type].splice(i, 1);
    }
  }
  ).catch((err) => console.log('User dismissed the dialog ', err));
  else
  this.regra.generalSpending[type].splice(i, 1);

}

}
