import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/services/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ConfirmationDialogService } from 'src/app/components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-country',
  templateUrl: './list-country.component.html',
  styleUrls: ['./list-country.component.css']
})
export class ListCountryComponent implements OnInit {
  eventsSubject: Subject<any> = new Subject<any>();

  countrys: any[];
  tipos: any =[];
  displayedColumns: string[] = ['name','createdBy','createdAt','updatedBy', 'status', 'actions'];
  imageColumn = [ ]
  columnsToDisplay = [ {field: 'name', header: 'Nome', type: 'text'}, {field: 'createdAt', header: 'Data criação', type: 'date'},
  {field: 'createdBy', header: 'Criado por', type: 'text'}, {field: 'updatedBy', header: 'Editado por', type: 'text'}];
    columnsBadge = [{field: 'status', header: 'Estado', type: 'select', selectFieldsDados:  [ {name: 'Ativo', val: 'ACTIVE'}, {name: 'Inativo', val: 'INACTIVE'}]}];
    options = {edit: true, view: true, delete: false, statusUpdate: false};
  title = 'Países';
  novo = {link: '/paises/criar', nome: 'Novo País'};
  page: any = {base: null, name: null};
  service;

  constructor(private countryService: CountryService,
    private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    private toast: ToastrService,
    private router: Router,) {
this.service = this.countryService.getAll;
}

ngOnInit(): void {
  this.page.base = this.route.data['value'].base;
  this.page.name = this.route.data['value'].routeName;
  }

  edit = (args: any): void => {
    console.log(args);
    this.router.navigate([this.page.base  + '/editar'], { queryParams: { id: args.id } });
    }
    view = (args: any): void => {
      console.log(args);
      this.router.navigate([this.page.base  + '/view'], { queryParams: { id: args.id } });
    }
    changeStatus = (args: any): void => {
    let textConfirm = '';
    let status = '';
    let textShow = '';

    let service;
    if (args.status === 'ACTIVE'){
      service = this.countryService.inactive(args.id);
      textConfirm = 'Pretende Desactivar o País' + args.name + '? Ao desactivar o País o estado do País será alterado para INATIVO.';
      status = 'INACTIVE';
      textShow = 'PAÍS DESATIVADO COM SUCESSO';
    }
    else{
      service = this.countryService.active(args.id);
      textConfirm = 'Pretende Activar a country ' + args.name + '? Ao activar o País o estado do País será alterado para ATIVO';
      status = 'ACTIVE';
      textShow = 'PAÍS ACTIVADO COM SUCESSO';
    }

    this.confirmationDialogService.confirm('Por favor confirme', textConfirm)
    .then((confirmed) =>
    {
      if (confirmed){
        service.subscribe((event: any) => {
          this.toast.warning(textShow, 'Mudança de Estado');
          args.status = status;
      });
      }
    }
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

    delete = (total: any, args: any, index: number): void => {
      this.confirmationDialogService.confirm('Por favor confirme', 'Pretende apagar este país ' + args.name + '? Ao eliminar os dados do país serão perdidos!!')
      .then((confirmed) =>
      {
        if (confirmed){
          const indexo = total.indexOf(args);
          this.countryService.delete(args.id).pipe(first())
      .subscribe(
          data => {
            this.toast.warning('Eliminado com sucesso', 'Delete');
            total.splice(indexo, 1);
            console.log('total', total);
            this.eventsSubject.next(total);
          },
          error => {
              console.log('error', error);
          });
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
      console.log(args);
    }

  }
