<div class="container-fluid">
    <h2 class="text-siscomeTot underline">{{ page.name === 'edit' ? 'Editar' : page.name === 'view' ? 'Visualizar' :'Criar' }} Nível</h2>
    <mat-card>
      <mat-card-content>
        <form [formGroup]="levelForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit(page.name)">
          <div class="row">
            <!-- <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Loja</mat-label>
              <select matNativeControl  [(ngModel)]="level.store"  id="loja" [ngClass]="{ 'is-invalid': submitted && levelForm.controls['loja'].hasError('required' || 'invalid') }"
                name="loja" formControlName="loja" >
                <option *ngFor="let store of lojas; let i = index;" [selected]="store.id === level.store.id" label="{{store.name}}"  [ngValue]="store"></option>
                </select>
                <div class="invalid-feedback" *ngIf="levelForm.controls['loja'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field> -->
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Nome</mat-label>
              <mat-hint class="text-siscomeMed">Nome do Nível</mat-hint>
              <input class="form-control" [(ngModel)]="level.name" id="name" matInput placeholder="Nome do Nível" required
                name="name" formControlName="name">
                <div class="invalid-feedback" *ngIf="levelForm.controls['name'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Descrição</mat-label>
              <textarea class="form-control" [(ngModel)]="level.description" id="description" matInput placeholder="Descrição sobre o Nível" required
                name="description" formControlName="description"></textarea>
              <div class="invalid-feedback" *ngIf="levelForm.controls['description'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Descrição das informações do Nível</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Condição</mat-label>
              <input type="text" class="form-control" [(ngModel)]="level.conditionValue" id="conditionValue" matInput placeholder="Condição" required
                name="conditionValue" formControlName="conditionValue">
              <div class="invalid-feedback" *ngIf="levelForm.controls['rewardValue'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Condição para chegar ao nível</mat-hint>
            </mat-form-field>
          </div>
          <div class="row" >
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Código do prémio</mat-label>
              <input type="text" class="form-control" [(ngModel)]="level.rewardCode" id="rewardCode" matInput placeholder="Código do prémio" required
                name="rewardCode" formControlName="rewardCode">
              <div class="invalid-feedback" *ngIf="levelForm.controls['rewardCode'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Código do prémio a ganhar ao chegar no nível</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Nome do prémio</mat-label>
              <input type="text" class="form-control" [(ngModel)]="level.rewardName" id="rewardName" matInput placeholder="Nome do prémio" required
                name="rewardName" formControlName="rewardName">
              <div class="invalid-feedback" *ngIf="levelForm.controls['rewardName'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Nome do prémio a ganhar ao chegar no nível</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Valor do prémio</mat-label>
              <input type="number" class="form-control" [(ngModel)]="level.rewardValue" id="rewardValue" matInput placeholder="Valor do prémio" required
                name="rewardValue" formControlName="rewardValue">
              <div class="invalid-feedback" *ngIf="levelForm.controls['rewardValue'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Valor para chegar ao nível</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Valor mínimo de pedido</mat-label>
              <input type="number" class="form-control" [(ngModel)]="level.minOrderValue" id="minOrderValue" matInput placeholder="Valor mínimo de pedido" required
                name="minOrderValue" formControlName="minOrderValue">
              <div class="invalid-feedback" *ngIf="levelForm.controls['minOrderValue'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Valor mínimo de pedido em $</mat-hint>
            </mat-form-field>
          </div>

        </form>
      </mat-card-content>
      <mat-card-actions align="center">
        <button mat-raised-button *ngIf="page.name !== 'view'" class="siscomeTot large-button"  color="primary" (click)="onSubmit(page.name)">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ page.name === 'edit' ? 'Guardar' : 'Criar' }}</button>
          <button mat-raised-button *ngIf="page.name === 'view'"   class="siscomeTot" (click)="editar()" color="grey">Editar
          </button>
          <button mat-raised-button   class="grey" (click)="cancelar()" color="grey">{{ page.name === 'create' ? 'Cancelar' : 'Voltar' }}
          </button>
      </mat-card-actions>
    </mat-card>
  </div>

