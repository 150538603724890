import { MenuListComponentComponent } from './components/menu-list-component/menu-list-component.component';
import { HomeComponent } from './views/home/home.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { TabelaComponent } from './components/tabela/tabela.component';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DoBootstrap, ApplicationRef, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
// import {MatCardModule} from '@angular/material/card';
import {DemoMaterialModule} from './material-module';
import {MatTableModule} from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {ErrorInterceptorService } from './services/error-interceptor.service';
import {MatNativeDateModule} from '@angular/material/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { KeycloakAuthService } from './services/keycloak.service';
import { AuthInterceptor } from './services/authInterceptor.service';
import { KeycloakAngularModule } from 'keycloak-angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { EmailComponent } from './components/email/email.component';
import { NgxEditorModule } from 'ngx-editor';
import { ListLojasComponent } from './views/lojas/list-lojas/list-lojas.component';
import { CreateLojasComponent } from './views/lojas/create-lojas/create-lojas.component';
import { ListCityComponent } from './views/parametros/cidades/list-city/list-city.component';
import { CreateCityComponent } from './views/parametros/cidades/create-city/create-city.component';
import { ListCountryComponent } from './views/parametros/paises/list-country/list-country.component';
import { CreateCountryComponent } from './views/parametros/paises/create-country/create-country.component';
import { ListZoneComponent } from './views/parametros/zonas/list-zone/list-zone.component';
import { CreateZoneComponent } from './views/parametros/zonas/create-zone/create-zone.component';
import { ListClienteComponent } from './views/clientes/list-cliente/list-cliente.component';
import { CreateClienteComponent } from './views/clientes/create-cliente/create-cliente.component';
import { CreateMerchantComponent } from './views/merchant/create-merchant/create-merchant.component';
import { ListMerchantComponent } from './views/merchant/list-merchant/list-merchant.component';
import { CreateRegrasComponent } from './views/regras/create-regras/create-regras.component';
import { ListRegrasComponent } from './views/regras/list-regras/list-regras.component';
import { ListTiposRegrasComponent } from './views/parametros/tiposRegras/list-tipos-regras/list-tipos-regras.component';
import { CreateTiposRegrasComponent } from './views/parametros/tiposRegras/create-tipos-regras/create-tipos-regras.component';
import { ListTargetRegrasComponent } from './views/parametros/targetRegras/list-target-regras/list-target-regras.component';
import { CreateTargetRegrasComponent } from './views/parametros/targetRegras/create-target-regras/create-target-regras.component';
import { CreateSegmentComponent } from './views/seguimentos/create-segment/create-segment.component';
import { ListSegmentComponent } from './views/seguimentos/list-segment/list-segment.component';
import { CreatePremiosComponent } from './views/premios/create-premios/create-premios.component';
import { ListPremiosComponent } from './views/premios/list-premios/list-premios.component';
import { ListRewardTypeComponent } from './views/parametros/tipoPremios/list-reward-type/list-reward-type.component';
import { CreateRewardTypeComponent } from './views/parametros/tipoPremios/create-reward-type/create-reward-type.component';
import { ListSegmentTypeComponent } from './views/parametros/tipoSegmento/list-segment-type/list-segment-type.component';
import { CreateSegmentTypeComponent } from './views/parametros/tipoSegmento/create-segment-type/create-segment-type.component';
import { CreateLevelComponent } from './views/levels/create-level/create-level.component';
import { ListLevelComponent } from './views/levels/list-level/list-level.component';
import { AddEmployComponent } from './components/add-employ/add-employ.component';
import { ListTransactionsComponent } from './views/transactions/list-transactions/list-transactions.component';
import { ListRedinRewardsComponent } from './views/redinRewards/list-redin-rewards/list-redin-rewards.component';

export function kcFactory(keycloak:KeycloakAuthService) {
 return ()=> keycloak.init();
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorDialogComponent,
    TabelaComponent,
    HomeComponent,
    SidenavComponent,
    MenuListComponentComponent,
    EmailComponent,
    ListLojasComponent,
    CreateLojasComponent,
    ListCityComponent,
    CreateCityComponent,
    ListCountryComponent,
    CreateCountryComponent,
    ListZoneComponent,
    CreateZoneComponent,
    ListClienteComponent,
    CreateClienteComponent,
    CreateMerchantComponent,
    ListMerchantComponent,
    CreateRegrasComponent,
    ListRegrasComponent,
    ListTiposRegrasComponent,
    CreateTiposRegrasComponent,
    ListTargetRegrasComponent,
    CreateTargetRegrasComponent,
    CreateSegmentComponent,
    ListSegmentComponent,
    CreatePremiosComponent,
    ListPremiosComponent,
    ListRewardTypeComponent,
    CreateRewardTypeComponent,
    ListSegmentTypeComponent,
    CreateSegmentTypeComponent,
    CreateLevelComponent,
    ListLevelComponent,
    AddEmployComponent,
    ListTransactionsComponent,
    ListRedinRewardsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GoogleMapsModule,
    KeycloakAngularModule,
    DemoMaterialModule,
    HttpClientModule,
    MatProgressBarModule,
    NgbPaginationModule,
    NgbAlertModule,
    CommonModule,
    MatDialogModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatMenuModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    NgxEditorModule,
    NgbModule
  ],
  providers: [
    { provide: APP_INITIALIZER, deps: [KeycloakAuthService], multi: true, useFactory: kcFactory },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
