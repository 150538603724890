import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/services/country.service';

@Component({
  selector: 'app-create-country',
  templateUrl: './create-country.component.html',
  styleUrls: ['./create-country.component.css']
})
export class CreateCountryComponent implements OnInit {

  pais: any = {};
  items;
  paisForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl = '/paises';
    error = '';
    page: any = {base: null, name: null};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastrService,
    private countryService: CountryService) { }

    ngOnInit(): void {
      this.page.base = this.route.data['value'].base;
      this.page.name = this.route.data['value'].routeName;

      console.log('this.page', this.page);

      if (this.page.name === 'edit' || this.page.name === 'view'){
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          this.countryService.get(id).pipe(first())
          .subscribe(
              data => {
                console.log('data bem', data);
                this.pais = data;
              },
              error => {
                console.log('error', error);
              });
        });
      }
      else if (this.page.name === 'create'){
      }
      this.paisForm = this.fb.group({
        name: new FormControl('', Validators.required)
    });
    if (this.page.name === 'view')
    this.paisForm.disable();

    }

      onSubmit(page): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.paisForm.invalid) {
          console.log('invaid', this.paisForm);
          return;
        }
        console.log('valid', this.pais);
        this.loading = true;

        if (page === 'edit'){
          this.countryService.update(this.pais, this.pais.id)
            .subscribe(
                data => {
                  this.toast.success('Editaste um País com sucesso', 'Edição', { positionClass: 'toast-top-center' });
                  this.router.navigate([this.page.base]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });

        }
        else if (page === 'create'){
          this.countryService.create(this.pais)
          .pipe(first())
          .subscribe(
              data => {
                this.toast.success('Criaste um País com sucesso', 'Criação', { positionClass: 'toast-top-center' });
                this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.error = error;
                  this.loading = false;
              });
        }
    }
    cancelar(): void{
      this.router.navigate([this.page.base]);
    }
    editar(): void{
      this.page.name = 'edit';
      this.paisForm.enable();
    }

}
