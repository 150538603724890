<div class="modal-header">
  <h4 class="modal-title text-siscome">{{ title }}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="formulario" (keydown.enter)="$event.preventDefault()">
      <mat-form-field appearance="outline" class="form-group col-6">
        <mat-label class="text-siscomeMed">Nome</mat-label>
              <input matInput type="text" placeholder="Título" formControlName="name" id="name" name="name" required
              [(ngModel)]="empregado.name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
              <div *ngIf="submitted && f.name.errors" class="text-danger">
                <div *ngIf="f.name.errors.required">Nome é Obrigatório</div>
              </div>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-group col-6">
        <mat-label class="text-siscomeMed">Data Nascimento</mat-label>
              <input matInput type="date" placeholder="Data Nascimento" formControlName="birthdate" id="birthdate" name="birthdate" required
              [(ngModel)]="empregado.birthdate" [ngClass]="{ 'is-invalid': submitted && f.birthdate.errors }">
              <div *ngIf="submitted && f.birthdate.errors" class="text-danger">
                <div *ngIf="f.birthdate.errors.required">Data Nascimento é Obrigatório</div>
              </div>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-group col-6">
        <mat-label class="text-siscomeMed">Endereço</mat-label>
              <textarea matInput type="text" placeholder="Endereço" formControlName="address" id="address" name="address" required
              [(ngModel)]="empregado.address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
              <div *ngIf="submitted && f.address.errors" class="text-danger">
                <div *ngIf="f.address.errors.required">Endereço é Obrigatório</div>
              </div>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-group col-6">
        <mat-label class="text-siscomeMed">Phone</mat-label>
              <input matInput type="text" placeholder="Phone" formControlName="phone" (keyup)="checkPhone($event,empregado.phone)" id="phone" name="phone" required
              pattern="^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$"
              [(ngModel)]="empregado.phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
              <div *ngIf="submitted && f.phone.errors" class="text-danger">
                <div *ngIf="f.phone.errors.required">Phone é Obrigatório</div>
                <div *ngIf="f.phone.errors.invalid">o Campo Telefone está Inválido</div>
                <div *ngIf="f.phone.errors.pattern">o Campo Telefone está Inválido, deverá estar no formato "xxx-xxx-xxxx" e conter somente números</div>
              </div>
              <div class="text-danger" *ngIf="!submitted &&  f.phone.errors">
                <div *ngIf="f.phone.errors.pattern">o Campo Telefone está Inválido, deverá estar no formato "xxx-xxx-xxxx" e conter somente números</div>
              </div>
      </mat-form-field>

    </form>
</div>
<div class="modal-footer">
  <button align="center" mat-raised-button class="siscomeMed large-button" color="primary" style="margin: 2px !important" (click)="addEmpregado(empregado)">{{ btnOkText }}</button>
  <button align="end" mat-raised-button class="grey" color="grey" (click)="cancelar()">{{ btnCancelText }}</button>
</div>

