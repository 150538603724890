<div class="container-fluid">
  <h2 class="text-siscomeTot underline">{{ page.name === 'edit' ? 'Editar' : page.name === 'view' ? 'Visualizar' :'Criar' }} Comerciante</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="comercianteForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit(page.name)">
        <div class="row">
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Nome</mat-label>
            <input class="form-control" [(ngModel)]="comerciante.firstName" id="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" matInput placeholder="Nome do comerciante" required
              name="name" formControlName="name">
              <div class="invalid-feedback" *ngIf="submitted && f.name.errors">
                <div *ngIf="f.name.errors.required">o Campo Nome é Obrigatório</div>
                <div *ngIf="f.name.errors.invalid">o Campo Nome está Inválido</div>
              </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Apelido</mat-label>
            <input class="form-control" [(ngModel)]="comerciante.lastName" id="apelido" matInput placeholder="Apelido do comerciante" required
              name="apelido" formControlName="apelido" [ngClass]="{ 'is-invalid': submitted && f.apelido.errors }">
              <div class="invalid-feedback" *ngIf="submitted && f.apelido.errors">
                <div *ngIf="f.apelido.errors.required">o Campo Apelido é Obrigatório</div>
                <div *ngIf="f.apelido.errors.invalid">o Campo Apelido está Inválido</div>
              </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Email</mat-label>
            <input class="form-control" [(ngModel)]="comerciante.email" type="email" id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
             matInput placeholder="Email do comerciante" required disabled
              name="email" formControlName="email">
              <div class="invalid-feedback" *ngIf="submitted && f.email.errors">
                <div *ngIf="f.email.errors.required">o Campo Email é Obrigatório</div>
                <div *ngIf="f.email.errors.invalid">o Campo Email está Inválido</div>
              </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">Telefone</mat-label>
            <input  class="form-control" [(ngModel)]="comerciante.phone" (keyup)="checkPhone($event,comerciante.phone)" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
             pattern="^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$" id="phone" matInput
             placeholder="238-000-0000" required
              name="phone" formControlName="phone">
              <div class="invalid-feedback" *ngIf="submitted && f.phone.errors">
                <div *ngIf="f.phone.errors.required">o Campo Telefone é Obrigatório</div>
                <div *ngIf="f.phone.errors.invalid">o Campo Telefone está Inválido</div>
                <div *ngIf="f.phone.errors.pattern">o Campo Telefone está Inválido, deverá estar no formato "xxx-xxx-xxxx" e conter somente números</div>
              </div>
              <div class="text-danger" *ngIf="!submitted &&  f.phone.errors">
                <div *ngIf="f.phone.errors.pattern">o Campo Telefone está Inválido, deverá estar no formato "xxx-xxx-xxxx" e conter somente números</div>
              </div>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="outline" class="form-group col-6">
            <mat-label class="text-siscomeMed">NIF (TIN)</mat-label>
            <input class="form-control" type="number" [(ngModel)]="comerciante.tin" (keypress)="maxLenght($event, comerciante.tin, 9)" id="nif" matInput placeholder="Nif do comerciante" required
              name="nif" formControlName="nif" [ngClass]="{ 'is-invalid': submitted && f.nif.errors }">
              <div class="invalid-feedback" *ngIf="submitted && f.nif.errors">
                <div *ngIf="f.nif.errors.required">o Campo NIF é Obrigatório</div>
                <div *ngIf="f.nif.errors.invalid">o Campo NIF está Inválido</div>
              </div>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="center">
      <button mat-raised-button *ngIf="page.name !== 'view'" class="siscomeTot large-button"  color="primary" (click)="onSubmit(page.name)">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ page.name === 'edit' ? 'Guardar' : 'Criar' }}</button>
        <button mat-raised-button *ngIf="page.name === 'view'"   class="siscomeTot" (click)="editar()" color="grey">Editar
        </button>
        <button mat-raised-button   class="grey" (click)="cancelar()" color="grey">{{ page.name === 'create' ? 'Cancelar' : 'Voltar' }}
        </button>
    </mat-card-actions>
  </mat-card>
</div>
