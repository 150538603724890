import { Component, OnInit } from '@angular/core';
import { KeycloakAuthService } from 'src/app/services/keycloak.service';
import { Router, ActivatedRoute  } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(protected authService: KeycloakAuthService,
              private route: ActivatedRoute,
              private router: Router,) {

  }

  ngOnInit(): void {}

}
