<div class="container-fluid">
  <h2 class="text-siscomeTot underline">{{ page.name === 'edit' ? 'Editar' : page.name === 'view' ? 'Visualizar' :'Criar' }} Loja</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="lojaForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit(page.name)">
        <mat-tab-group mat-align-tabs="start" animationDuration="2000ms"  [(selectedIndex)]="tabIndex"
        style="padding-top: 10px; padding-bottom: 10px;  padding-left: 2%; padding-right: 2%;">
          <mat-tab label="Identificação da Loja" class="col-sm-12">
            <mat-card>
              <mat-card-content class="activity">
                <div class="row">
                  <mat-form-field appearance="outline" class="form-group col-6">
                    <mat-label class="text-siscomeMed">Nome</mat-label>
                    <mat-hint class="text-siscomeMed">Nome da Loja</mat-hint>
                    <input class="form-control" [(ngModel)]="loja.name" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['name'].hasError('required' || 'invalid') }"
                     id="name" matInput placeholder="Nome da Loja" required
                      name="name" formControlName="name">
                      <div class="invalid-feedback" *ngIf="lojaForm.controls['name'].hasError('required')">
                        o Campo é Obrigatório
                      </div>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="form-group col-6">
                    <mat-label class="text-siscomeMed">Identificador</mat-label>
                    <mat-hint class="text-siscomeMed">Identificador que destingue a loja</mat-hint>
                    <input class="form-control" [(ngModel)]="loja.identifier" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['identifier'].hasError('required' || 'invalid') }"
                     id="identifier" matInput placeholder="Identificador" required
                      name="identifier" formControlName="identifier">
                      <div class="invalid-feedback" *ngIf="lojaForm.controls['identifier'].hasError('required')">
                        o Campo é Obrigatório
                      </div>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field appearance="outline" class="form-group col-4">
                    <mat-label class="text-siscomeMed">País</mat-label>
                    <select matNativeControl  [(ngModel)]="country"  id="country" [compareWith]="equals"  (change)="getCities(country, true)" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['country'].hasError('required' || 'invalid') }"
                      name="country" formControlName="country" required >
                      <option *ngFor="let pais of paises; let i = index;"  label="{{pais.name}}"  [ngValue]="pais"></option>
                      </select>
                      <div class="text-red" *ngIf="submitted && lojaForm.controls['country'].hasError('required')">
                        o Campo é Obrigatório
                      </div>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="form-group col-4">
                    <mat-label class="text-siscomeMed">Cidade</mat-label>
                    <mat-hint class="text-siscomeMed">Escolher País primeiro</mat-hint>
                    <select matNativeControl  [(ngModel)]="city"  id="city" (change)="getZones(city, true)" [compareWith]="equals" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['city'].hasError('required' || 'invalid') }"
                      name="city" formControlName="city" required >
                      <option   label="Escolha uma Cidade"></option>
                      <option *ngFor="let cidade of cidades; let i = index;"  label="{{cidade.name}}"  [ngValue]="cidade"></option>
                      </select>
                      <div class="text-red" *ngIf="submitted && lojaForm.controls['city'].hasError('required')">
                        o Campo é Obrigatório
                      </div>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="form-group col-4">
                    <mat-label class="text-siscomeMed">Zona</mat-label>
                    <mat-hint class="text-siscomeMed">Escolher Cidade primeiro</mat-hint>
                    <select matNativeControl  [(ngModel)]="loja.zone"  id="zone" [compareWith]="equals"
                     [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['zone'].hasError('required' || 'invalid') }"
                      name="zone" formControlName="zone" required>
                      <option   label="Escolha uma Zona"></option>
                        <option  *ngFor="let zone of zones; let i = index;"  [ngValue]="zone" label="{{zone.name}}"></option>
                      </select>
                      <div class="text-red" *ngIf="submitted && lojaForm.controls['zone'].hasError('required')">
                        o Campo é Obrigatório
                      </div>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field appearance="outline" class="form-group col-12">
                    <mat-label>Descrição</mat-label>
                    <textarea class="form-control" [(ngModel)]="loja.description" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['description'].hasError('required' || 'invalid') }" id="description"
                     matInput placeholder="Descrição sobre a loja" required
                      name="description" formControlName="description"></textarea>
                    <div class="invalid-feedback" *ngIf="lojaForm.controls['description'].hasError('required')">
                      o Campo é Obrigatório
                    </div>
                    <mat-hint>Descrição das informações da Loja</mat-hint>
                  </mat-form-field>
                </div>
                <div  style="text-align:center; " class="row">
                  <div  class="col-6">
                    <div class="row">
                      <mat-card style="width: 100% !important;" *ngIf="photo">
                        <mat-card-header>
                          <img mat-card-avatar class="example-header-image" src="{{photo.imgURL}}">
                        </mat-card-header>
                        <img mat-card-image width="100" height="200" src="{{photo.imgURL}}" alt="Profile image">
                        <mat-card-actions>
                          <button mat-icon-button *ngIf="page.name !== 'view'" type="button" (click)="removeFile('photo', photo, 'DEFAULT')">
                            <mat-icon aria-hidden="false"  title="Apagar" aria-label="Delete"  color="warn">delete</mat-icon></button>
                        </mat-card-actions>
                      </mat-card>
                    </div>
                    <button type="button" mat-button color="primary" *ngIf="page.name !== 'view'"  (click)="onClick('photoUpload', 'photo')">
                      <mat-icon>file_upload</mat-icon>
                      Adicionar Foto Perfil
                    </button>
                    <input type="file" #photoUpload id="photoUpload" name="photoUpload" multiple="false" accept="image/*" style="display:none;" />
                  </div>
                  <div  class="col-6">
                    <div class="row">
                      <mat-card style="width: 100% !important;" *ngIf="profileCoverPhoto">
                        <mat-card-header>
                          <img mat-card-avatar class="example-header-image" src="{{profileCoverPhoto.imgURL}}">
                        </mat-card-header>
                        <img mat-card-image width="100" height="200" src="{{profileCoverPhoto.imgURL}}" alt="Cover image">
                        <mat-card-actions>
                          <button mat-icon-button *ngIf="page.name !== 'view'" type="button" (click)="removeFile('profileCoverPhoto', profileCoverPhoto, 'PROFILECOVER')">
                            <mat-icon aria-hidden="false" title="Apagar" aria-label="Delete"  color="warn">delete</mat-icon></button>
                        </mat-card-actions>
                      </mat-card>
                    </div>
                    <button type="button" mat-button color="primary" *ngIf="page.name !== 'view'"  (click)="onClick('coverUpload', 'profileCoverPhoto')">
                      <mat-icon>file_upload</mat-icon>
                      Adicionar Foto Capa
                    </button>
                    <input type="file" #coverUpload id="coverUpload" name="coverUpload" multiple="false" accept="image/*" style="display:none;" />
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-tab>
          <mat-tab label="Configurações de Níveis" class="col-sm-12" >
            <div class="row">
              <mat-form-field appearance="outline" class="form-group col-4">
                <mat-label class="text-siscomeMed">Critério de Validação</mat-label>
                <select matNativeControl  [(ngModel)]="loja.typeCriterion"   id="criterio" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['criterio'].hasError('required' || 'invalid') }"
                  name="criterio" formControlName="criterio"  >
                  <option  label="Por Pontos"  [ngValue]="'Ponto'"></option>
                  <option  label="Por Pedidos"  [ngValue]="'Pedidos'"></option>
                  <option  label="Ambos"  [ngValue]="'Ambos'"></option>
                  </select>
                  <div class="text-red" *ngIf="submitted && lojaForm.controls['criterio'].hasError('required')">
                    o Campo é Obrigatório
                  </div>
              </mat-form-field>
            </div>
            <span formArrayName="levelsConfig">
              <mat-card  *ngFor="let level of levels; let i = index;" [formGroupName]="i">
                <mat-card-header class="text-siscomeTot">{{level.name}}</mat-card-header>
                  <mat-card-content class="activity" >
                    <div class="row">
                        <mat-form-field appearance="outline" class="form-group col-6">
                          <mat-label class="text-siscomeMed">Pontos Mínimos</mat-label>
                          <mat-hint class="text-siscomeMed">Pontos Mínimos para chegar ao nível</mat-hint>
                          <input class="form-control" [(ngModel)]="levelConfigList[i].minPoints" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['levelsConfig']['controls'][i]['controls']['minPoints'].hasError('required' || 'invalid') }"
                           id="minPoints" matInput placeholder="Pontos Mínimos para chegar ao nível" [required]="loja.typeCriterion === 'Ponto' || loja.typeCriterion === 'Ambos'"
                            name="minPoints" formControlName="minPoints">
                            <div class="invalid-feedback" *ngIf="lojaForm.controls['levelsConfig']['controls'][i]['controls']['minPoints'].hasError('required')">
                              o Campo é Obrigatório
                            </div>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="form-group col-6">
                          <mat-label class="text-siscomeMed">Pontos Máximos</mat-label>
                          <mat-hint class="text-siscomeMed">Pontos Máximos para estar no nível</mat-hint>
                          <input class="form-control" [(ngModel)]="levelConfigList[i].maxPonits" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['levelsConfig']['controls'][i]['controls']['maxPonits'].hasError('required' || 'invalid') }"
                           id="maxPonits" matInput placeholder="Pontos Máximos para estar no nível" [required]="loja.typeCriterion === 'Ponto' || loja.typeCriterion === 'Ambos'"
                            name="maxPonits" formControlName="maxPonits">
                            <div class="invalid-feedback" *ngIf="lojaForm.controls['levelsConfig']['controls'][i]['controls']['maxPonits'].hasError('required')">
                              o Campo é Obrigatório
                            </div>
                        </mat-form-field>
                      </div>
                      <div class="row">
                        <mat-form-field appearance="outline" class="form-group col-6">
                          <mat-label class="text-siscomeMed">Valor Mínimo de Pedido</mat-label>
                          <mat-hint class="text-siscomeMed">Valor Mínimo de Pedido para quem esta no nível</mat-hint>
                          <input class="form-control" [(ngModel)]="levelConfigList[i].minOrderValue" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['levelsConfig']['controls'][i]['controls']['minOrderValue'].hasError('required' || 'invalid') }"
                           id="minOrderValue" matInput placeholder="Valor Mínimo de Pedido para quem esta no nível" [required]="loja.typeCriterion === 'Pedidos' || loja.typeCriterion === 'Ambos'"
                            name="minOrderValue" formControlName="minOrderValue">
                            <div class="invalid-feedback" *ngIf="lojaForm.controls['levelsConfig']['controls'][i]['controls']['minOrderValue'].hasError('required')">
                              o Campo é Obrigatório
                            </div>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="form-group col-6">
                          <mat-label class="text-siscomeMed">Valor Máximo de Pedido</mat-label>
                          <mat-hint class="text-siscomeMed">Valor Máximo de Pedido para quem esta no nível</mat-hint>
                          <input class="form-control" [(ngModel)]="levelConfigList[i].maxOderValue" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['levelsConfig']['controls'][i]['controls']['maxOderValue'].hasError('required' || 'invalid') }"
                           id="maxOderValue" matInput placeholder="Valor Máximo de Pedido para quem esta no nível" [required]="loja.typeCriterion === 'Pedidos' || loja.typeCriterion === 'Ambos'"
                            name="maxOderValue" formControlName="maxOderValue">
                            <div class="invalid-feedback" *ngIf="lojaForm.controls['levelsConfig']['controls'][i]['controls']['maxOderValue'].hasError('required')">
                              o Campo é Obrigatório
                            </div>
                        </mat-form-field>
                      </div>
                  </mat-card-content>
                </mat-card>
            </span>

            </mat-tab>
          <mat-tab label="Caixas de Atendimento" class="col-sm-12">
            <mat-card>
              <mat-card-content class="activity">
                <div class="row">
                  <mat-form-field appearance="outline" class="form-group col-sm-8">
                    <mat-label class="text-siscomeMed">Identificador</mat-label>
                    <mat-hint class="text-siscomeMed">Identificador que destingue as caixas</mat-hint>
                    <input class="form-control" [(ngModel)]="cashier.workstationIdentifier" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['workstationIdentifier'].hasError('required' || 'invalid') }"
                     id="workstationIdentifier" matInput placeholder="Identificador"
                      name="workstationIdentifier" formControlName="workstationIdentifier">
                      <div class="invalid-feedback" *ngIf="lojaForm.controls['workstationIdentifier'].hasError('required')">
                        o Campo é Obrigatório
                      </div>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="form-group col-sm-3">
                    <mat-label class="text-siscomeMed">Pin</mat-label>
                    <mat-hint class="text-siscomeMed">Pin de Acesso por parte dos trabalhadores</mat-hint>
                    <input class="form-control" [(ngModel)]="cashier.pin" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['pin'].hasError('required' || 'invalid') }"
                     id="pin" matInput placeholder="Pin de Acesso"
                      name="pin" formControlName="pin">
                      <div class="invalid-feedback" *ngIf="lojaForm.controls['pin'].hasError('required')">
                        o Campo é Obrigatório
                      </div>
                  </mat-form-field>
                  <div class="form-group col-sm-1" *ngIf="page.name !== 'view'">
                    <button title="Adicionar Caixa"  mat-icon-button type="button"  class="purple right " (click)="addCashier(cashier)">
                      <mat-icon>add</mat-icon></button>
                  </div>
                </div>
                <div class="row" >
                  <mat-card *ngFor="let cashier of this.loja.cachierList; let i = index;" class="col-12">
                    <mat-card-header class="col-12 text-center">
                        <p>
                          <button type="button" class="btn btn-link" (click)="toggleCashier(i)" >
                            {{cashier.workstationIdentifier}} - {{cashier.pin}}
                          </button>
                        <a  *ngIf="page.name !== 'view'" title="Editar"  mat-icon-button aria-label="Edit" label="Edit" color="primary" (click)="editCashier(i)">
                          <mat-icon aria-hidden="false">edit</mat-icon>
                        </a>
                        <a  *ngIf="page.name !== 'view'"  title="Apagar"  mat-icon-button aria-label="Delete" color="warn" (click)="deleteCashier(cashier, i)">
                          <mat-icon aria-hidden="false" >delete</mat-icon>
                        </a>
                        <a  *ngIf="page.name !== 'view'" title="Adicionar Empregado"  mat-icon-button aria-label="Delete" color="primary" (click)="addEmploy(i)">
                          <mat-icon aria-hidden="false">add</mat-icon>
                        </a>
                    </p>
                    </mat-card-header>
                    <mat-card-content class="activity col-12" id="collapse{{i}}">
                      <table>
                        <tr *ngIf="cashier.assignedEmployeeList.length !== 0">
                          <th class="">Nome</th>
                          <th class="">Data Aniversário</th>
                          <th class="">Endereço</th>
                          <th class="">Telefone</th>
                          <th *ngIf="page.name !== 'view'">Acções</th>
                        </tr>
                        <tr *ngFor="let employee of cashier.assignedEmployeeList; let x = index;">
                          <td>{{employee.name}}</td>
                          <td>{{employee.birthdate}}</td>
                          <td>{{employee.address}}</td>
                          <td>{{employee.phone}}</td>
                          <td *ngIf="page.name !== 'view'">
                            <a  title="Editar"  mat-icon-button aria-label="Edit" label="Edit" color="primary" (click)="editEmploy(employee,i,x)">
                              <mat-icon aria-hidden="false">edit</mat-icon>
                            </a>
                            <a title="Apagar"  mat-icon-button aria-label="Delete" color="warn" (click)="deleteEmploy(cashier,i, x)">
                              <mat-icon aria-hidden="false" >delete</mat-icon>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </mat-card-content>
                  </mat-card>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-tab>
          <mat-tab label="Horários de Funcionamento" class="col-sm-12">
            <mat-card>
              <mat-card-content class="activity">
                <div class="row">
                  <mat-form-field appearance="outline" class="form-group col-4">
                    <mat-label class="text-siscomeMed">Período de Funcionamento</mat-label>
                    <select matNativeControl  [(ngModel)]="loja.timeOpen"   id="periodo" [ngClass]="{ 'is-invalid': submitted && lojaForm.controls['periodo'].hasError('required' || 'invalid') }"
                      name="periodo" formControlName="periodo"  >
                      <option  label="1 Período"  [ngValue]="false"></option>
                      <option  label="2 Período"  [ngValue]="true"></option>
                      </select>
                      <div class="text-red" *ngIf="submitted && lojaForm.controls['periodo'].hasError('required')">
                        o Campo é Obrigatório
                      </div>
                  </mat-form-field>
                </div>
                <table formArrayName="weekDays">
                  <tr>
                    <th >Dia de Semana</th>
                    <th >Início {{loja.timeOpen ? '1º Período': ''}}</th>
                    <th >Fecho {{loja.timeOpen ? '1º Período': ''}}</th>
                    <th *ngIf="loja.timeOpen"  >Início 2º Período</th>
                    <th *ngIf="loja.timeOpen" >Fecho 2º Período</th>
                  </tr>
                    <tr *ngFor="let day of weekDays; let i = index;" [formGroupName]="i">
                      <td>
                          {{day.name}}
                      </td>
                      <td>
                        <mat-form-field appearance="fill" class="form-group col-12">
                          <input type="time" class="form-control" [(ngModel)]="storeOpeningHoursList[i].firstStartHour"
                            id="startHour" matInput placeholder="Hora Início"
                            name="startHour" formControlName="startHour">
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="fill" class="form-group col-12">
                          <input type="time" class="form-control" [(ngModel)]="storeOpeningHoursList[i].firstendHour"
                            id="endHour" matInput placeholder="Hora Fim"
                            name="endHour" formControlName="endHour">
                        </mat-form-field>
                      </td>
                      <td *ngIf="loja.timeOpen === true">
                        <mat-form-field appearance="fill" class="form-group col-12">
                          <input type="time" class="form-control" [(ngModel)]="storeOpeningHoursList[i].secondStartHour"
                            id="secondStartHour" matInput placeholder="Hora ínicio"
                            name="secondStartHour" formControlName="secondStartHour">
                        </mat-form-field>
                      </td>
                      <td *ngIf="loja.timeOpen === true">
                        <mat-form-field appearance="fill" class="form-group col-12">
                          <input type="time" class="form-control" [(ngModel)]="storeOpeningHoursList[i].secondEndHour"
                            id="secondEndHour" matInput placeholder="Hora Fim"
                            name="secondEndHour" formControlName="secondEndHour">
                        </mat-form-field>
                      </td>
                    </tr>

                </table>

              </mat-card-content>
            </mat-card>

          </mat-tab>
          <mat-tab label="Galeria de Imagens" class="col-sm-12">
            <mat-card>
              <mat-card-content class="activity">
                <div class="row">
                  <mat-card style="width: 50% !important; margin: 2px;" class="col-3" *ngFor="let file of files; let x = index" >
                    <mat-card-header>
                      <img mat-card-avatar class="example-header-image" src="{{file.imgURL}}">
                    </mat-card-header>
                    <img mat-card-image width="100" height="200" src="{{file.imgURL}}" alt="Galery image">
                    <mat-card-actions>
                      <button mat-icon-button type="button" *ngIf="page.name !== 'view'" (click)="removeFileFromList(x,file)">
                        <mat-icon aria-hidden="false" title="Apagar" aria-label="Delete"  color="warn">delete</mat-icon></button>
                    </mat-card-actions>
                  </mat-card>
                </div>
                <div style="text-align:center; margin: 5px;" class="row">
                  <button type="button" mat-button color="primary"  *ngIf="page.name !== 'view'" (click)="onClick('fileUpload', 'files')">
                    <mat-icon>file_upload</mat-icon>
                    Outras Imagens
                  </button>
                  <button mat-raised-button color="warn" title="Apagar Todas" type="button"  *ngIf="(files.length > 0 && page.name !== 'view')" (click)="removeAll()">Eliminar Todas Imagens</button>
                  <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display:none;" />
                </div>
              </mat-card-content>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </form>
    </mat-card-content>
    <mat-card-actions align="center">
      <button mat-raised-button *ngIf="page.name !== 'view'" class="siscomeTot large-button"  color="primary" (click)="onSubmit(page.name)">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ page.name === 'edit' ? 'Guardar' : 'Criar' }}</button>
        <button mat-raised-button *ngIf="page.name === 'view'"   class="siscomeTot" (click)="editar()" color="grey">Editar
        </button>
        <button mat-raised-button   class="grey" (click)="cancelar()" color="grey">{{ page.name === 'create' ? 'Cancelar' : 'Voltar' }}
        </button>
    </mat-card-actions>
  </mat-card>
</div>

