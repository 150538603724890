<div class="container-fluid">
  <h2 class="text-siscomeTot underline">{{ page.name === 'edit' ? 'Editar' : page.name === 'view' ? 'Visualizar' :'Criar' }} Prémio</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="premioForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit(page.name)">
         <div class="container-fluid paddingBottom" >
          <div class="row">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Loja</mat-label>
              <select matNativeControl  [(ngModel)]="premio.store"  id="loja" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['loja'].hasError('required' || 'invalid') }"
                name="loja" formControlName="loja" >
                <option *ngFor="let store of lojas; let i = index;" [selected]="store.storeId === premio.store.storeId" label="{{store.name}} {{store.identifier}}"  [ngValue]="store"></option>
                </select>
                <div class="invalid-feedback" *ngIf="premioForm.controls['loja'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Nome</mat-label>
              <mat-hint class="text-siscomeMed">Nome do prémio</mat-hint>
              <input class="form-control" [(ngModel)]="premio.name" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['name'].hasError('required' || 'invalid') }"
               id="name" matInput placeholder="Nome do prémio" required
                name="name" formControlName="name">
                <div class="invalid-feedback" *ngIf="premioForm.controls['name'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Descrição</mat-label>
              <textarea class="form-control" [(ngModel)]="premio.description" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['description'].hasError('required' || 'invalid') }"
               id="description" matInput placeholder="Descrição sobre o prémio" required
                name="description" formControlName="description"></textarea>
              <div class="invalid-feedback" *ngIf="premioForm.controls['description'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Descrição das informações do prémio</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label>Descrição da condição de Prémio</mat-label>
              <textarea class="form-control" [(ngModel)]="premio.conditionDescription" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['conditionDescription'].hasError('required' || 'invalid') }"
               id="conditionDescription" matInput placeholder="Descrição sobre o prémio" required
                name="conditionDescription" formControlName="conditionDescription"></textarea>
              <div class="invalid-feedback" *ngIf="premioForm.controls['conditionDescription'].hasError('required')">
                o Campo é Obrigatório
              </div>
              <mat-hint>Descrição da condição para ganhar o prémio</mat-hint>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Data Início</mat-label>
              <mat-hint class="text-siscomeMed">Data apartir da qual o prémio estará disponível</mat-hint>
              <input type="datetime-local" class="form-control" [(ngModel)]="premio.startDate" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['startDate'].hasError('required' || 'invalid') }"
               id="startDate" matInput placeholder="Data Início"
                name="startDate" formControlName="startDate">
                <div class="invalid-feedback" *ngIf="premioForm.controls['startDate'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Data fim</mat-label>
              <mat-hint class="text-siscomeMed">Data até quando o prémio estará disponível</mat-hint>
              <input type="datetime-local" class="form-control" [(ngModel)]="premio.endDate" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['endDate'].hasError('required' || 'invalid') }"
               id="endDate" matInput placeholder="Data fim"
                name="endDate" formControlName="endDate">
                <div class="invalid-feedback" *ngIf="premioForm.controls['endDate'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="outline" class="form-group col-4">
              <mat-label class="text-siscomeMed">Limite de Clientes</mat-label>
              <mat-hint class="text-siscomeMed">Quantidade de clientes que poderão ganhar o prémio</mat-hint>
              <input type="number" class="form-control" [(ngModel)]="premio.limitClient" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['limitClient'].hasError('required' || 'invalid') }"
               id="limitClient" matInput placeholder="Limite de Clientes" required
                name="limitClient" formControlName="limitClient">
                <div class="invalid-feedback" *ngIf="premioForm.controls['limitClient'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-4">
              <mat-label class="text-siscomeMed">Valor Limite</mat-label>
              <mat-hint class="text-siscomeMed">Valor limite que poderá ganhar o prémio</mat-hint>
              <input type="number" class="form-control" [(ngModel)]="premio.limitValue" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['limitValue'].hasError('required' || 'invalid') }"
               id="limitValue" matInput placeholder="Valor Limite" required
                name="limitValue" formControlName="limitValue">
                <mat-error *ngIf="premioForm.controls['limitValue'].hasError('required')">
                  o Campo é Obrigatório
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group col-4">
              <mat-label class="text-siscomeMed">Pontos</mat-label>
              <mat-hint class="text-siscomeMed">Pontos para ganhar o prémio</mat-hint>
              <input type="number" class="form-control" [(ngModel)]="premio.points" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['points'].hasError('required' || 'invalid') }"
               id="points" matInput placeholder="Pontos para ganhar o prémio" required
                name="points" formControlName="points">
                <mat-error *ngIf="premioForm.controls['points'].hasError('required')">
                  o Campo é Obrigatório
                </mat-error>
            </mat-form-field>
          </div>
         </div>
         <div class="container-fluid paddingBottom" >
          <div class="row">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Tipo</mat-label>
              <select matNativeControl  [(ngModel)]="premio.rewardType"  id="tipo" [ngClass]="{ 'is-invalid': submitted && premioForm.controls['tipo'].hasError('required' || 'invalid') }"
                name="tipo" formControlName="tipo" >
                <option *ngFor="let type of tipos; let i = index;" [selected]="type.id === premio.rewardType.id" label="{{type.name}}"  [ngValue]="type"></option>
                </select>
                <div class="invalid-feedback" *ngIf="premioForm.controls['tipo'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
          </div>
          <div class="row" *ngIf="premio.rewardType.typesReward === 'CASHBACK'">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Pontos Válidos</mat-label>
              <mat-hint class="text-siscomeMed">Quantidade de pontos que cada escudo proporcionará</mat-hint>
              <input type="number" class="form-control" [(ngModel)]="premio.cashBack.pointValue" id="pointValue" matInput placeholder="Pontos Válidos" required
                name="pointValue" formControlName="pointValue">
                <div class="invalid-feedback" *ngIf="premioForm.controls['pointValue'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
          </div>
         </div>
         <div class="container-fluid paddingBottom" >
          <div class="row">
            <mat-form-field appearance="outline" class="form-group col-6">
              <mat-label class="text-siscomeMed">Target</mat-label>
              <select matNativeControl  (change)="getTipos(premio.targetTypeEnum, false)" [(ngModel)]="premio.targetTypeEnum" [disabled]="!premio.store.storeId"  id="target"
               [ngClass]="{ 'is-invalid': submitted && premioForm.controls['target'].hasError('required' || 'invalid') }"
                name="target" formControlName="target" >
                <option *ngFor="let target of targets; let i = index;" [selected]="target === premio.targetTypeEnum" label="{{target}}"  [ngValue]="target"></option>
                </select>
                <div class="invalid-feedback" *ngIf="premioForm.controls['target'].hasError('required')">
                  o Campo é Obrigatório
                </div>
            </mat-form-field>
          </div>
          <div class="container-fluid  atividadeProcedimentos" *ngIf="premio.targetTypeEnum === 'LEVEL'" >
            <div class=row>
              <section class="form-group col-12">
                <h6 class="text-siscomeMed ">Levels*</h6>
                <div formArrayName="levels"  class="form-group row">
                  <div class="col-3" *ngFor="let level of levels; let i = index;"  [formGroupName]="i">
                    <mat-checkbox   class="example-margin" [disabled]="page.name === 'view'" formControlName="level"  [(ngModel)]="levelsList[i]">
                      {{level.name}}</mat-checkbox>
                  </div>
                </div>
                <div *ngIf="submitted && premioForm.controls['levels'].hasError('required' || 'invalid')" class="text-danger">
                  <div *ngIf="premioForm.controls['levels'].hasError('required')"><small>Pelo menos um LEVEL deve ser selecionado</small></div>
                </div>
              </section>
            </div>
          </div>
          <div class="container-fluid  atividadeProcedimentos" *ngIf="premio.targetTypeEnum === 'SEGMENT'" >
            <div class=row>
              <section class="form-group col-12">
                <h6 class="text-siscomeMed ">Seguimentos do Cliente*</h6>
                <div formArrayName="segments"  class="form-group row">
                  <div class="col-3" *ngFor="let segment of segments; let i = index;"  [formGroupName]="i">
                    <mat-checkbox   class="example-margin" [disabled]="page.name === 'view'" formControlName="segment"  [(ngModel)]="segmentsList[i]">
                      {{segment.name}}</mat-checkbox>
                  </div>
                </div>
                <div *ngIf="submitted && premioForm.controls['segments'].hasError('required' || 'invalid')" class="text-danger">
                  <div *ngIf="premioForm.controls['segments'].hasError('required')"><small>Pelo menos um Seguimento deve ser selecionado</small></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="center">
      <button mat-raised-button *ngIf="page.name !== 'view'" class="siscomeTot large-button"  color="primary" (click)="onSubmit(page.name)">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ page.name === 'edit' ? 'Guardar' : 'Criar' }}</button>
        <button mat-raised-button *ngIf="page.name === 'view'"   class="siscomeTot" (click)="editar()" color="grey">Editar
        </button>
        <button mat-raised-button   class="grey" (click)="cancelar()" color="grey">{{ page.name === 'create' ? 'Cancelar' : 'Voltar' }}
        </button>
    </mat-card-actions>
  </mat-card>
</div>
