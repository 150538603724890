import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import * as Keycloak from 'keycloak-js';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

//declare var  Keycloak: any;
const keycloakAuth = new KeycloakService();



@Injectable({
  providedIn: 'root'
})
export class KeycloakAuthService {



  auth: any = {};
  public currentUser: any = {};
  private currentUserSubject: BehaviorSubject<any>;

  constructor(private router: Router,) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('auth')));
    this.auth.authz = this.currentUserSubject.asObservable();
  }


  public get currentUserValue(): any {
    return this.currentUserSubject.value;
}

  init(): Promise<any> {
    this.auth.loggedIn = false;

    return new Promise<any>((resolve, reject) => {
      keycloakAuth.init(
          {
            config: environment.keycloak,
            initOptions: {
              onLoad: 'login-required',
              checkLoginIframe: false,
            },
            enableBearerInterceptor: true,
            bearerPrefix: 'Bearer',
            bearerExcludedUrls: [
                '/assets',
                '/clients/public']
        })
          .then((response) => {
            console.log('response', response);
            console.log('keycloakAuth', keycloakAuth);
            localStorage.setItem('auth', JSON.stringify(keycloakAuth));
              /*keycloakAuth.updateToken(5).then((refreshed) => {
                  if (refreshed) {
                    localStorage.setItem('token', JSON.stringify(refreshed));
                    console.log('Token refreshed' + refreshed);
                  }else{
                  }
                }).catch(() => {
                  console.error('Failed to refresh token');
                });*/
                keycloakAuth.getToken().then((refreshed) => {
                  if (refreshed) {
                    localStorage.setItem('token', JSON.stringify(refreshed));
                    console.log('Token refreshed' + refreshed);
                  }else{
                    console.log('Token  not refreshed');
                  }
                }).catch(() => {
                  console.error('Failed to refresh token');
                });
            this.auth.loggedIn = true;
            this.auth.authz = keycloakAuth;
            this.currentUserSubject.next(keycloakAuth);
            resolve(keycloakAuth);
          })
          .catch((error) => {
            console.error('init Keycloak failed', error);
            reject();
         });
        });
    }

 logout(): void {
    console.log('*** LOGOUT');
    console.log(' KeycloakAuthService',  KeycloakAuthService);
    localStorage.removeItem('auth');
    console.log(' this.auth.authz ',  this.auth.authz );
    this.currentUserSubject.next(null);
    this.auth.loggedIn = false;
    this.auth.authz = null;
    this.auth.logoutUrl = environment.keycloak.url + '/realms/' + environment.keycloak.realm + '/protocol/openid-connect/logout?redirect_uri=' + environment.landimPage ;
    window.location.href = this.auth.logoutUrl;
  }

  profile():void{
    var profileUrl = environment.keycloak.url + '/realms/' + environment.keycloak.realm + '/account?referrer=sicosme-frontend&redirect_uri=' + environment.landimPage ;
    console.log(' currentUser',  this.currentUserValue);
    window.location.href = profileUrl;
  }


  sign(): void {
    console.log('*** REGISTO');
    const url = environment.keycloak.url + '/realms/Sicosme/login-actions/registration?client_id=sicosme-frontend&tab_id=8m1kZTcAvS8';
    //window.location.href = url;
    keycloakAuth.init(
      {
        config: environment.keycloak,
        initOptions: {
          checkLoginIframe: false,
          redirectUri: environment.homePage,
        },
        enableBearerInterceptor: true,
        bearerPrefix: 'Bearer',
        bearerExcludedUrls: [
            '/assets',
            '/clients/public']
    }).then((response) => {
      console.log('response', response);
      console.log('keycloakAuth', keycloakAuth);
      keycloakAuth.register().then((registo) => {
        console.log('login', registo);
      })
      .catch((error) => {
        console.error('init Keycloak failed', error);
     });
     /* setTimeout(() => {
        keycloakAuth.updateToken(60).then((refreshed) => {
            if(refreshed) {
              console.debug('Token refreshed' + refreshed);
            }else{
            }
          }).catch(() => {
            console.error('Failed to refresh token');
          });
      }, 40000)*/
    })
    .catch((error) => {
      console.error('init Keycloak failed', error);
   });
  }

/*getToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      console.log('Auth', KeycloakAuthService.auth);
      console.log('keycloakAuth', this.keycloakAuth);
      if (KeycloakAuthService.auth.authz.token) {
        KeycloakAuthService.auth.authz.updateToken(5)
          .success(() => {
            resolve( KeycloakAuthService.auth.authz.token as string);
          })
          .error(() => {
            reject('Failed to refresh token');
          });
      }
    });
  }*/
  getToken(): string {
    return this.auth.token;
  }

  getUsername(): string {
    return this.auth.authz.tokenParsed.preferred_username;
 }

  getFullName(): string {
    return this.auth.authz.tokenParsed.name;
 }

 isLoggedIn(): boolean {
  try {
    if (!this.auth.loggedIn) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}


}
