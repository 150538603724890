import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-redin-rewards',
  templateUrl: './list-redin-rewards.component.html',
  styleUrls: ['./list-redin-rewards.component.css']
})
export class ListRedinRewardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
